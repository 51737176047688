

.custom-checkbox {
  position: relative;
}

.checkbox {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.span-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  background: transparent;
  border: 1px solid #212121;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.forms-checkbox-wrapper:hover .span-checkbox {
  background: rgba(255, 255, 255, 0.1);
}

.checkbox:checked + .span-checkbox {
  background-color: $general-color;
  border-color: $general-color;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}

.checkbox:checked + .span-checkbox::after {
  content: "";
  position: absolute;
  top: calc(50% + 0.05em);
  left: calc(50% - 0.2em);
  width: 0.2em;
  height: 0.5em;
  border-right: 2px solid #212121;
  border-bottom: 2px solid #212121;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  50% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #212121;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
