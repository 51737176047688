@import "../../../styles/variables/variables.scss";

.input {
  border-radius: 8px;
  border: 2px solid $black-color;
  background: #fff;
  height: 48px;
  padding-left: 1rem;
  padding-right: 1rem;
  outline: none;
  transition: all 0.3s ease;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: "Poppins";

  &::placeholder {
    color: $placeholder-color;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 12px;
  }

  &:focus {
    border: 2px solid $general-color;
  }

  &:invalid {
    border: 2px solid $error-color;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.88);
  }

  &:disabled {
    border: 2px solid $gray-color;
  }
}

.error-field {
  border-color: $error-color;
  .mat-mdc-text-field-wrapper {
    border-color: $error-color;
  }
}

.error-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $error-color;
  font-size: 14px;
  padding-left: 0.5rem;
}

.sort-wrapper {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.input-wrapper {
  position: relative;
  width: 100%;

  .input {
    padding-right: 55px;
  }

  .textarea-wrapper textarea {
    padding-right: 30px;
  }

  .input,
  .textarea-wrapper {
    width: 100%;
  }

  .select-language {
    position: absolute;
    right: 3px;
    top: 6px;
    cursor: pointer;
    border: none !important;
    &:active {
      border: none;
    }
  }

  .clear-chips {
    padding: 0 25px;
  }

  .mat-focused {
    box-shadow: none;
  }
}

@media (max-width: $tablet-width) {
  .sort-wrapper {
    gap: 1rem;

    .custom-select {
      width: calc(50% - 0.5rem);
    }
  }
}

.search-wrapper {
  position: relative;

  .search-results {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
  }

  .search {
    width: 100%;
  }

  .error-wrapper {
    text-align: center;
    padding: 1rem;
    color: $text-color;
  }
}

.input-group {
  position: relative;
  .input {
    width: 100%;
  }
}

.toggle-password-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.toggle-password-btn img {
  width: 18px;
  height: 18px;
}

.textarea {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    height: 32px;
    border-radius: 7px;
    background: #000;
  }

  &::-webkit-scrollbar-button:vertical:increment,
  &::-webkit-scrollbar-button:vertical:decrement {
    height: 5px;
  }
}
