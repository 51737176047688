@import "../../../styles/variables/variables.scss";

.wrapper {
  padding: 4rem 4rem;
  display: flex;
  width: 100%;
}

@media (max-width: 1050px) {
  .wrapper {
    padding: 4rem 2rem;
  }
}

@media (max-width: $tablet-width) {
  .wrapper {
    padding: 1.625rem 1rem;
  }
}
