@use "igniteui-angular/theming" as *;

@include core();
@include typography();

$custom-palette: palette(
	$primary: #00d0c1,
	$secondary: #00d0c1,
	$surface: #fff,
	$gray: #000
  );
@include theme($palette: $custom-palette, $schema: $light-schema);

body {
	background: var(--ig-surface-500);
	color: var(--ig-surface-500-contrast);
}
@import 'minireset.css/minireset';
@import "./styles/reset.scss";
@import "./styles/typography/typography.scss";
@import "./styles/variables/variables.scss";
@import "./styles/components/components.scss";
@import "./styles/ui/shared-ui.scss";
@import "./styles/material.scss";

img {
  object-fit: cover;
}

html,
body {
  margin: 0;
  color: #000;
}

.page-wrapper {
  padding: 0 1rem;
  padding-bottom: 2rem;
}


.cdk-overlay-container {
  z-index: 99999;
}
