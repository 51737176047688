.report-list {
  // height: 100%;
  min-height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .report-item {
    padding: 0 0.5rem;
    width: 100%;
    height: 56px;
    background-color: transparent;
    color: #000;
    font-size: 16px;
    border-bottom: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    cursor: pointer;
    padding: 0 1.5rem;
    text-align: center;

    &:last-child {
      border-bottom: none;
    }

    .btn-svg {
      height: 14px;
      width: 8px;
    }
  }
}

.report-text-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  flex: 1;
  margin-top: 1rem;

  .report-text {
    font-family: "Poppins";
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  .textarea {
    border: 2px solid #000;
    max-height: 282px;
    height: 100%;
  }

  .report-btn {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #fff;
    margin-top: 4.5rem;
  }
}
