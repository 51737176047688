.custom-select {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  height: 48px;
  background-color: #fff;

  &:hover {
    background: #fcfbfb;
  }

  .arrow {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .mat-mdc-select-arrow svg{
    color: $secondary-color;
  }

  .arrow svg {
    transition: transform 0.3s ease;
  }

  &:focus-within .arrow svg {
    transform: rotate(180deg);
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-text-field-wrapper {
  border-radius: 8px !important;
  border: 2px solid $black-color;
}

.mdc-line-ripple::before,
.mdc-line-ripple::after {
  border-bottom: none !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-top: 9px !important;
  padding-bottom: 14px !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
  .mat-mdc-form-field-infix {
  padding-top: 23px !important;
}

.mat-mdc-select-placeholder,
.mat-mdc-select-min-line {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-weight: 600;
}

.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mat-mdc-form-field
  .mat-mdc-select.mat-mdc-select-invalid
  .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54) !important;
}

div.mat-mdc-select-panel,
div.mat-mdc-autocomplete-panel {
  border-radius: 8px !important;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 32px;
    border-radius: 7px;
    background: $gray-color;
  }

  &::-webkit-scrollbar-button:vertical:increment,
  &::-webkit-scrollbar-button:vertical:decrement {
    height: 5px;
  }
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  max-height: 290px !important;
}

.select-language {
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
      .mdc-text-field--textarea
    )
    .mat-mdc-form-field-infix {
    padding-top: 6px !important;
    padding-bottom: 8px !important;
  }
}

.search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 48px;
  background-color: #fff;
  z-index: 9999;
  width: 100%;
  max-height: 290px !important;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 32px;
    border-radius: 7px;
    background: $gray-color;
  }

  &::-webkit-scrollbar-button:vertical:increment,
  &::-webkit-scrollbar-button:vertical:decrement {
    height: 5px;
  }
  li {
    padding: 12px 20px;
    border-top: none;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }

  li:hover {
    background-color: #f6f6f6;
  }
}

// @media (max-width: 460px) {
//   .custom-select:not(.desktop-select) {
//     height: 38px;

//     .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
//         .mdc-text-field--textarea
//       )
//       .mat-mdc-form-field-infix {
//       padding-top: 4px !important;
//       padding-bottom: 14px !important;
//     }
//   }
// }
