@import './variables/variables.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a {
  text-decoration: none;
  color: $text-color;
}

html, body{
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
}

:root {
  --vh: 1vh;
}

body{
  height: 100%;
}


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.overflow{
  height: 100%;
  width: 100%;
}

.mdc-slider .mdc-slider__thumb {
  user-select: none;
  -webkit-touch-callout: none;
  touch-action: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.mdc-slider .mdc-slider__thumb {
  touch-action: pan-y;
}

.mat-mdc-slider .mdc-slider__input {
  touch-action: pan-x;
}

.mdc-slider {
  pointer-events: auto;
}

button{
  padding: 0;
}

// html {
//   min-height: calc(var(--vh, 1vh)* 100);
// }
