
.slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  border-radius: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: -1;
}

.send-gift-slide {
  z-index: 96;
  background-color: #292d32;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: default;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem;
  min-height: max-content;

  .gift-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 1.5rem;
    border-radius: 16px;

    .gift-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.25rem;
      border: 1px solid #000;
      cursor: pointer;
      color: #fff;
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      padding-top: 0.5rem;
      padding-bottom: 0.25rem;
      font-family: "IBM";
      transition: all 0.3s ease;

      &:nth-child(7),
      &:last-child {
        border-bottom: none;
      }

      &:nth-child(7) {
        border-bottom-left-radius: 16px;
      }

      &:last-child {
        border-bottom-right-radius: 16px;
      }

      .gift-name {
        text-transform: capitalize;
        margin-top: -0.25rem;
      }

      .gift-icon {
        width: 75px;
        height: 69px;
      }
      .price-img {
        width: 15px;
        height: 15px;
      }

      .gift-price {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }

    .active-gift {
      background-color: $secondary-color;
    }
  }

  .btn {
    width: 80%;
    color: #fff;
    margin: 0.25rem auto 0 auto;
  }

  .close-slide {
    top: 0.5rem;
    &:hover,
    &:active {
      background-color: #3c4249;
    }
  }
}

.textarea-wrapper {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  margin: auto auto 0 auto;
  min-height: 155px;
  width: 100%;
}
.send-gift-textarea {
  width: 100%;
  border: 2px solid #000;
  transition: all 0.3s ease;
  min-height: 115px;
  &:focus {
    border: 2px solid #000;
    outline: none;
  }
}

.gift-send-popup {
  display: none;
}

.reports-btn-wrapper {
  position: relative;
}

.gift-is-send {
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 1rem;
  .btn {
    margin-top: 0;
  }
}

.popup-btn-wrapper {
  z-index: 98;
  top: -3rem;
}

.report-slide {
  background-color: #f3f3f3;
  border: 1px solid #000;
  border-radius: 16px;
  padding: 1rem 2.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 96;
  cursor: default;
  min-height: min-content;
  overflow-y: auto;
  position: relative;

  .report-title {
    color: $secondary-color;
    font-size: 32px;
    font-weight: 700;
  }

  .report-subtitle {
    font-size: 16px;
    margin-top: 1rem;
    font-weight: 700;
    color: $secondary-color;
  }

  .report-list {
    // height: 100%;
    min-height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 3.75rem;

    .report-item {
      width: 100%;
      height: 55px;
      background-color: #fff;
      color: #000;
      font-size: 22px;
      border-radius: 50px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      padding: 0 1.5rem;
      text-align: center;
    }
  }

  .report-text-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3.5rem;
    gap: 1rem;
    flex: 1;

    .report-text {
      font-family: "Poppins";
      color: #000;
      font-size: 16px;
      font-weight: 500;
    }

    .textarea {
      border: 2px solid #000;
      max-height: 282px;
      height: 100%;
    }

    .report-btn {
      color: #fff;
      margin-top: auto;
    }
  }
}

.close-slide {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0.25rem;
  transition: all 0.1s;
  img {
    width: 15px;
    height: 15px;
    color: #fff;
  }
  &:hover,
  &:active {
    background-color: #e2e2e2;
  }
}

.report-sended {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .send-report-title {
    font-size: 24px;
    font-weight: 700;
    color: $secondary-color;
    text-align: center;
    margin-top: 3.75rem;
    line-height: 32px;
  }

  .send-report-text {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: $secondary-color;
    margin-top: 0.5rem;
    line-height: 24px;
  }

  .report-btn {
    color: #fff;
    margin-top: auto;
    width: 100%;
  }
}
.send-gift-title {
  text-align: center;
  color: #fff;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

@media (max-height: 900px) {
  .send-gift-slide {
    // position: relative;
    .gift-list {
      height: calc(
        100vh -
          (
            155px + 0.5rem + 60px + 0.25rem + 20px + 36px + 2.375rem + 1.5rem +
              1rem
          )
      );
      min-height: 220px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        height: 32px;
        border-radius: 7px;
        background: #fff;
      }

      &::-webkit-scrollbar-button:vertical:increment,
      &::-webkit-scrollbar-button:vertical:decrement {
        height: 5px;
      }
    }
  }

  .report-slide .report-list {
    margin-top: 1.5rem;
  }
}

@media (max-height: 1020px) and (max-width: $tablet-width) {
  .send-gift-slide {
    // position: relative;
    .gift-list {
      height: calc(
        90vh -
          (
            155px + 0.5rem + 60px + 0.25rem + 20px + 36px + 2.375rem + 1.5rem +
              1rem + 77px
          )
      );
      min-height: 220px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        height: 32px;
        border-radius: 7px;
        background: #fff;
      }

      &::-webkit-scrollbar-button:vertical:increment,
      &::-webkit-scrollbar-button:vertical:decrement {
        height: 5px;
      }
    }
  }
}
