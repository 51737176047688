.page-wrapper {
  display: flex;
  flex-direction: column;
  // min-height: calc(100vh - 56px - 2rem);
  flex: 1;
}

.toggle-password-btn{
  img{
    object-fit: contain;
  }
}

.login-header {
  min-height: 56px;
  width: 100%;
  background-color: $general-color;
  height: 56px;
  border: 2px solid $black-color;
  box-shadow: 4px 4px 0px #000;
  border-radius: 8px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 32px;
  color: #fff;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .subtitle {
    padding-left: 1rem;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins";
  }
}

.forms-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  .forms-checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .forgot-password,
  .forms-checkbox-wrapper {
    font-size: 14px;
    font-weight: 400;
  }
}

.submit-btn {
  margin-top: 3rem;
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  width: 100%;
}

.footer-login {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;

  .span,
  .link {
    color: $secondary-color;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins";
  }

  .link {
    text-decoration: underline;
  }

  .fs16,
  .link {
    font-size: 16px;
    font-family: "IBM";
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;

    .submit-btn {
      margin-top: 0;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.separator {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 600;
  font-size: 9.41px;
  font-family: Inter;
}

.separator::before,
.separator::after {
  content: "";
  width: 92.5px;
  height: 1px;
  background-color: #9e9e9e;
}

.separator::before {
  margin-right: 10px;
}

.separator::after {
  margin-left: 10px;
}

.alter-login {
  display: flex;
  gap: 0.5rem;

  .alter-login-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.page-title {
  padding: 1.25rem 0;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.page-info {
  font-size: 16px;
  color: $secondary-color;
  line-height: 24px;
  padding-bottom: 1.5rem;
  text-align: center;
}

.select-buttons {
  display: flex;
  gap: 0.5rem;

  .select-btn {
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 50px;
    height: 40px;
    padding: 0 1.5rem;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.2s;
    color: #000;

    &.active {
      border: 2px solid $secondary-color;
      background-color: $secondary-color;
      color: #fff;
    }
  }
}

.back-btn {
  border-radius: 50%;
  background-color: $secondary-color;
  width: 70px;
}

.progress-wrapper {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .progress-name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $secondary-color;
  }

  .progress-bar {
    width: 100%;
    position: relative;
    height: 4px;

    .progress {
      height: 4px;
      background-color: #000;
      width: var(--previous-width);
      animation: fillProgress 1s ease-in-out forwards;
    }
  }
}

@keyframes fillProgress {
  0% {
    width: var(--previous-width);
  }
  100% {
    width: var(--progress-width);
  }
}

.upload-img-form {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
  margin-bottom: 1rem;
  position: relative;

  .preloader {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }


  .upload-img-btn {
    background-color: #f3f3f3;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    border-radius: 16px;
    position: relative;
    flex: 1;
    border: 1px solid transparent;
    transition: all 0.2s ease;

    .upload-img {
      opacity: 0;
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 100%;
    }
  }

  .image-preview {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-size: cover;
    flex: 1;
  }

  .delete-img-btn {
    background-color: transparent;
    border: none;
    color: $secondary-color;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.more-photos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  height: 100%;
  margin-bottom: 1rem;
}

.select-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .select-target {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1rem;
    border: 1px solid #f3f3f3;
    background-color: #f3f3f3;
    border-radius: 8px;
    transition: all 0.2s;
    cursor: pointer;
    &.active-select {
      background-color: #fff;
      border-color: $secondary-color;
    }
  }
}

.textarea {
  width: 100%;
  height: 115px;
  padding: 14px;
  font-size: 13px;
  border-radius: 8px;
  border: 1px solid #000;
  resize: none;
  outline: none;
  font-family: "IBM";
}

.info-buttons {
  margin-bottom: 1rem;

  .subtitle {
    margin-bottom: 0.5rem;
  }
}

.buttons-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 2px solid #000;
  box-shadow: 4px 4px 0px #000;

  .info-buttons {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.875rem 1rem;

    .btn-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
    .btn-svg {
      height: 14px;
      width: 8px;
      margin-top: 16px;
    }
    &:not(:last-child) {
      border-bottom: 2px solid #000;
    }
  }
}

.custom-login-tabs {
  .mat-mdc-tab-header  .mat-mdc-tab {
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 32px;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    line-height: 24px;
    font-weight: 600;
    transition: all 0.2s;
    font-family: "IBM";
    margin: 0 0.5rem;
    min-width: auto;
    height: auto;
    max-width: max-content;
    .mdc-tab__text-label {
      color: #000;
    }

    &.mat-mdc-focus-indicator {
      border-radius: 32px;
    }

    &.mdc-tab--active {
      border: 2px solid $secondary-color;
      background-color: $secondary-color;
      .mdc-tab__text-label {
        color: #fff;
      }

      .mdc-tab-indicator {
        display: none;
      }
    }
  }
}

.city-search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 48px;
  background-color: #fff;
  z-index: 9999;
  width: 100%;
  max-height: 9rem !important;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 32px;
    border-radius: 7px;
    background: $secondary-color;
  }

  &::-webkit-scrollbar-button:vertical:increment,
  &::-webkit-scrollbar-button:vertical:decrement {
    height: 5px;
  }
  li {
    padding: 12px 20px;
    border-top: none;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }

  li:hover {
    background-color: #f6f6f6;
  }
}

.city-search-wrapper {
  display: flex;
  position: relative;

  .input-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .input {
      border: none;
      padding-left: 0;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.city-input-wrapper {
  position: relative;
  .input-wrapper {
    display: flex;
    position: relative;
    .input {
      width: 100%;
    }
  }
  .city-search-results {
    width: 100%;
  }
}

.login-img,
.login-logo-img,
.download-btn-wrapper,
.desc-header-title,
.login-footer-wrapper {
  display: none;
}

.login-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .login-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }
}
.mobile {
  display: none;
}

@media (min-width: 460px) {
  .popup-form-group {
    margin-top: 1.5rem;
    .height-input {
      width: 100%;
    }
  }
  .city-input-wrapper .city-search-results {
    top: 38px;
  }
  .mobile {
    display: none;
  }
  .login-header {
    display: none;
  }

  .page-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 2rem !important;
    gap: 3.375rem;
    // min-height: calc(100vh - 46px);
  }

  .login-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    flex: 0;
  }

  .login-content .login-wrapper {
    flex: 0;
    padding: 1.5rem 2.5rem;
    padding-top: 3rem;
    // min-width: 313px;
    // width: 100%;
    // max-width: 440px;
    width: 313px;
    border: 1px solid #b0b8cf;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .login-logo-img {
    display: block;
    margin: 0 auto;
  }

  .input {
    height: 38px;
    width: 232px;
    border: 1px solid #b0b8cf;
    border-radius: 8px;
    font-size: 12px;
    &:active {
      border: 1px solid $general-color;
    }

    &:invalid {
      border: 1px solid $error-color;
    }
  }
  .input-group .input {
    width: 232px;
  }

  .submit-btn {
    height: 34px;
    width: 232px;
    font-size: 16px;
    order: 1;
    margin-top: 0;
  }

  .toggle-password-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: 18px;
      height: 18px;
    }
  }

  .forgot-password {
    font-size: 14px;
    text-align: center;
    color: $secondary-color;
    order: 2;
    margin-bottom: 0.375rem;
  }

  .footer-login {
    gap: 0.375rem;
  }

  .login-form-container {
    align-items: center;
    padding-top: 2.5rem;
  }
  .footer-login .dont-have-acc {
    color: #000;
    font-size: 14px;
    font-family: "Poppins";
    .link {
      font-weight: bold;
      font-size: 14px;
      font-family: "Poppins";
    }
  }

  .download-btn-wrapper {
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;

    .download-title {
      font-size: 14px;
      font-family: "Poppins";
    }

    .download-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 155px;
      height: 46px;

      .btn-text {
        font-size: 10px;
        font-family: "Poppins";
        color: #fff;
      }
    }
  }

  .error-wrapper {
    font-size: 12px;
  }

  .desc-header-title {
    display: block;
    font-size: 24px;
    line-height: 32px;
    color: $secondary-color;
    text-align: center;
    margin-top: 1rem;
  }

  .form-group .subtitle {
    padding-left: 3px;
  }

  .login-content .login-footer-wrapper {
    display: flex;
    margin-top: 0.75rem;
    padding: 1.25rem;
    padding: 0;
    padding-right: 4px;
    flex: 0;
  }

  .submit-btn {
    border-width: 1px;
  }

  .page-title {
    color: $secondary-color;
    font-size: 24px;
    padding: 1rem 0;
  }

  .back-btn,
  .progress-bar {
    display: none;
  }

  .page-info {
    font-size: 13px;
    margin-top: 0.75rem;
    padding-bottom: 0;
    margin-top: 0;
    line-height: 18px;
  }

  .form-group {
    width: 100%;
  }

  .gender-group {
    align-items: center;
    gap: 0.625rem;
  }

  .progress-wrapper {
    margin-top: 1rem;
  }

  .form-group .subtitle {
    font-size: 14px;
    font-weight: 600;
  }

  .upload-img-form .upload-img-btn {
    height: 256px;
    flex: auto;
  }
  .submit-btn {
    margin-top: 0.5rem;
  }

  .date-group {
    margin-top: 1rem;
  }

  .select-btn {
    font-weight: 600;
    cursor: pointer;
  }

  .upload-img-form .image-preview {
    height: 256px;
    flex: auto;
  }

  .more-photos {
    gap: 14px;
  }

  .buttons-list:not(.profile-sector) {
    max-height: 78px;
    overflow-y: auto;
    gap: 0.5rem;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding-right: 6px;
    padding-bottom: 6px;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      height: 32px;
      border-radius: 7px;
      background: $secondary-color;
    }

    &::-webkit-scrollbar-button:vertical:increment,
    &::-webkit-scrollbar-button:vertical:decrement {
      height: 5px;
    }

    .info-buttons {
      height: 34px;
      padding: 2px 1rem;
      margin: 0;
      border: 2px solid #000;
      box-shadow: 4px 4px 0px #000;
      border-radius: 16px;
      transition: all 0.1s ease;
      cursor: pointer;

      .btn-svg {
        display: none;
      }
    }
    .error-field{
      border-color: #f04438;
    }
  }

  .error-field{
    border-color: #f04438;
  }
}

@media (min-width: 900px) {
  .login-img {
    display: block;
    height: auto;
    max-width: 693px;
    width: calc(100% - 313px - 3.75rem);
  }
}
