// @import "../../variables/variables.scss";

.send-gift-popup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: center;

  .project-img {
    height: 86px;
    width: 86px;
    border-radius: 50%;
    margin-top: -3.5rem;
    border: 1px solid #000;
  }

  .gift-list {
    width: 100%;

    .gift-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      cursor: pointer;

      .gift-icon {
        width: 76px;
        height: 76px;
        background-color: transparent;
        border-radius: 8px;
        padding: 0.5rem;
        transition: all 0.3s ease;
      }

      .price-img {
        width: 15px;
        height: 15px;
      }

      .gift-price {
        margin-top: 0.25rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      .gift-price-btn {
        height: 32px;
        font-size: 16px;
        color: #fff;
        width: 70px;
      }

      .active-gift {
        background-color: #ffffff2b;
      }
    }
  }

  .send-gift-title {
    font-size: 16px;
    font-weight: 700;
    font-family: "IBM";
    color: $secondary-color;
    text-align: center;
  }

  .review-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .star-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.875rem;

    .star-btn {
      transition: all 0.3s ease;
      cursor: pointer;
      color: #e9e9e9;
    }

    .active-star {
      color: $general-color;
    }
  }

  .send-gift-textarea {
    max-width: 100%;
    margin: 0;
  }

  .send-gift-btn {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
  }
  .send-gift-textarea {
    width: 100%;
    border: 1px solid #000;
    transition: all 0.3s ease;
    outline: none;
    &::placeholder {
      line-height: 24px;
      text-align: center;
      color: #d9d9d9;
    }
  }

  .error-wrapper {
    width: auto;
    padding-left: 0;
  }

  .after-title {
    font-size: 20px;
    line-height: 24px;
    color: $secondary-color;
    font-weight: 700;
  }
}


.gift-sended {
  gap: 2rem;
  padding-top: 1.5rem;
}

// .popup-content {
//   padding: 1rem;
//   display: flex;
//   flex-direction: column;
//   gap: 1.5rem;
//   background-color: #fff;
//   border-radius: 8px;
//   border: 2px solid $black-color;

//   .btn-wrapper {
//     display: flex;
//     gap: 1rem;
//     justify-content: flex-end;

//     .btn {
//       height: 38px;
//       color: #fff;
//       font-size: 18px;
//       padding: 0 0.5rem;
//     }

//     .close-btn {
//       border-color: #fff;
//       background-color: #000;
//     }

//     .delete-btn {
//       background-color: $error-color;
//     }
//   }

//   .popup-title {
//     font-size: 24px;
//     text-align: center;
//     font-weight: 600;
//   }

//   .popup-info {
//     font-size: 20px;
//     text-align: center;
//   }
// }

// .form-group {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 1rem;

//   .form-wrapper {
//     display: flex;
//     flex-direction: column;
//     gap: 0.5rem;

//     .input {
//       height: 42px;
//     }

//     .input-wrapper {
//       position: relative;

//       .input {
//         padding-right: 35px;
//         width: 100%;
//       }

//       .money-img {
//         position: absolute;
//         right: 10px;
//         top: 10px;
//         width: 24px;
//       }
//     }

//     .error-block {
//       color: $error-color;
//       padding-left: 0.2rem;
//     }
//   }

//   .btn-wrapper {
//     grid-column: span 2;
//     margin-right: 0.5rem;
//   }
// }

// .popup-content {
//   position: relative;

//   .x-mark-btn {
//     border: none;
//     background: transparent;
//     padding: 0;
//     position: absolute;
//     width: 15px;
//     height: 15px;
//     top: 15px;
//     right: 20px;
//     cursor: pointer;
//   }

//   .x-mark-btn::before,
//   .x-mark-btn::after {
//     content: "";
//     position: absolute;
//     width: 2px;
//     height: 100%;
//     background-color: black;
//     top: 0;
//     left: 50%;
//     transform: translateX(-50%);
//   }

//   .x-mark-btn::before {
//     transform: translateX(-50%) rotate(45deg);
//   }

//   .x-mark-btn::after {
//     transform: translateX(-50%) rotate(-45deg);
//   }
// }
