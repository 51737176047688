/// @access private
/// Defines printing styles for all components in the library.
@mixin layout {
    @media print {
        html,
        body,
        app-root {
            min-height: 100vh;
            min-width: 100vw;
            margin: 0;
        }

        app-root {
            display: block;
        }

        * {
            -webkit-print-color-adjust: exact;
            // Fix shadows if you print to PDF using chrome START
            -webkit-filter: opacity(1);
            filter: opacity(1);
            // Fix shadows if you print to PDF using chrome END
            print-color-adjust: exact;
            text-shadow: none !important;
        }

        a[href^='http']::after {
            content: '[' attr(href) ']';
            color: blue;
        }

        .igx-no-print {
            display: none !important;
        }

        // Forcing Grayscale Printing
        .igx-bw-print {
            // CSS3 filter, at the moment Webkit only. Prefix it for future implementations
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%); /* future-proof */
        }

        igx-circular-bar,
        igx-linear-bar,
        .igx-carousel__indicators,
        .igx-carousel__arrow--prev,
        .igx-carousel__arrow--next,
        .igx-ripple,
        .igx-grid__tbody-scrollbar,
        igx-switch__ripple,
        igx-virtual-helper {
            display: none !important;
        }

        igx-grid {
            .igx-grid-th__title,
            .igx-grid__td-text {
                white-space: unset !important;
                text-overflow: initial !important;
                overflow: visible !important;
            }
        }
    }
}
