@use 'sass:map';
@use 'sass:meta';
@use 'sass:color';
@use 'sass:math';
@use '../../base' as *;
@use './excel-filtering-theme' as *;
@use 'igniteui-theming/sass/animations' as *;
@use '../../themes/schemas' as *;

////
/// @group themes
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
/// @author <a href="https://github.com/desig9stein" target="_blank">Marin Popov</a>
////

/// @param {Map} $schema [$light-material-schema] - The schema used as basis for styling the component.
/// @param {Map} $elevations [null] - The elevations (shadows) map to be used.
/// @param {Color} $header-background [null] - The table header background color.
/// @param {Color} $header-text-color [null] - The table header text color.
/// @param {String} $header-border-width [null] - The border width used for header borders.
/// @param {String} $header-border-style [null] - The border style used for header borders.
/// @param {Color} $header-border-color [null] - The color used for header borders.
/// @param {Color} $header-selected-background [null] - The table header background color when selected (ex. column selection).
/// @param {Color} $header-selected-text-color [null] - The table header text color when selected (ex. column selection).
/// @param {Color} $sorted-header-icon-color [null] - The sort icon color when sorted.
/// @param {color} $sortable-header-icon-hover-color [null] - The icon color on hover in grid header when the column is sortable.
/// @param {Color} $content-background [null] - The table body background color.
/// @param {Color} $content-text-color [null] - The table body text color.
/// @param {Color} $ghost-header-text-color [null] - The dragged header text color.
/// @param {Color} $ghost-header-icon-color [null] - The dragged header icon color.
/// @param {Color} $ghost-header-background [null] - The dragged header background color.
/// @param {Color} $row-odd-background [null] - The background color of odd rows.
/// @param {Color} $row-even-background [null] - The background color of even rows.
/// @param {Color} $row-odd-text-color [null] - The text color of odd rows.
/// @param {Color} $row-even-text-color [null] - The text color of even rows.
/// @param {Color} $row-selected-background [null] - The selected row background color.
/// @param {Color} $row-selected-hover-background [null] - The selected row hover background color.
/// @param {Color} $row-selected-text-color [null] - The selected row text color.
/// @param {Color} $row-selected-hover-text-color [null] - The selected row hover text color.
/// @param {Color} $row-hover-background [null] - The hover row background color.
/// @param {Color} $row-hover-text-color [null] - The hover row text color.
/// @param {Color} $row-border-color [null] - The row bottom border color.
/// @param {String} $pinned-border-width [null] - The border width of the pinned border.
/// @param {String} $pinned-border-style [null] - The CSS border style of the pinned border.
/// @param {Color} $pinned-border-color [null] - The color of the pinned border.
/// @param {Color} $cell-active-border-color [null] - The border color for the currently active(focused) cell.
/// @param {Color} $cell-selected-background [null] - The selected cell background color.
/// @param {Color} $cell-selected-text-color [null] - The selected cell text color.
/// @param {Color} $cell-editing-background [null] - The background color of the cell being edited.
/// @param {Color} $cell-editing-foreground [null] - The cell text color in edit mode.
/// @param {Color} $cell-editing-focus-foreground [null] - The cell text color in edit mode on focus.
/// @param {Color} $cell-edited-value-color [null] - The text color of a cell that has been edited.
/// @param {Color} $cell-new-color [null] - The text color of a new, unedited cell. Used when adding new row in a grid.
/// @param {Color} $cell-disabled-color [null] - The text color of a disabled cell.
/// @param {Color} $cell-selected-within-background [null] - The background of the selected cell inside a selected row/column.
/// @param {Color} $cell-selected-within-text-color [null] - The color of the selected cell inside a selected row/column.
/// @param {Color} $edit-mode-color [null] - The color applied around the row/cell when in editing mode.
/// @param {Color} $edited-row-indicator [null] - The color applied to the edited row indicator line.
/// @param {Color} $resize-line-color [null] - The table header resize line color.
/// @param {Color} $drop-indicator-color [null] - The color applied to the line between the columns when dragging a column.
/// @param {Color} $grouparea-background [null] - The grid group area background color.
/// @param {Color} $grouparea-color [null] - The grid group area color.
/// @param {Color} $group-row-background [null] - The grid group row background color.
/// @param {Color} $group-row-selected-background [null] - The drop area background on drop color.
/// @param {Color} $group-label-column-name-text [null] - The grid group row column name text color.
/// @param {Color} $group-label-icon [null] - The grid group row icon color.
/// @param {Color} $group-label-text [null] - The grid group row text color.
/// @param {Color} $expand-all-icon-color [null] - The grid header expand all group rows icon color.
/// @param {Color} $expand-all-icon-hover-color [null] - The grid header expand all group rows icon hover color.
/// @param {Color} $expand-icon-color [null] - The grid row expand icon color.
/// @param {Color} $expand-icon-hover-color [null] - The grid row expand icon hover color.
/// @param {Color} $active-expand-icon-color [null] - The drop area background on drop color.
/// @param {Color} $active-expand-icon-hover-color [null] - The drop area background on drop color.
/// @param {Color} $group-count-background [null] - The grid group row cont badge background color.
/// @param {Color} $group-count-text-color [null] - The grid group row cont badge text color.
/// @param {Color} $drop-area-text-color [null] - The drop area text color.
/// @param {Color} $drop-area-icon-color [null] - The drop area icon color.
/// @param {Color} $drop-area-background [null] - The drop area background color.
/// @param {Color} $drop-area-on-drop-background [null] - The drop area background on drop color.
/// @param {Color} $filtering-background-and [null] - The background color of advanced filtering "AND" condition.
/// @param {Color} $filtering-background-and--focus [null] - The background color on focus/selected of advanced filtering "AND" condition.
/// @param {Color} $filtering-background-or [null] - The background color of advanced filtering "OR" condition.
/// @param {Color} $filtering-background-or--focus [null] - The background color on focus/selected of advanced filtering "OR" condition.
/// @param {Color} $filtering-header-background [null] - The background color of the filtered column header.
/// @param {Color} $filtering-header-text-color [null] - The text color of the filtered column header.
/// @param {Color} $filtering-row-background [null] - The background color of the filtering row.
/// @param {Color} $filtering-row-text-color [null] - The text color of the filtering row.
/// @param {Color} $excel-filtering-header-foreground [null] - The text color of the header in the excel style filtering.
/// @param {Color} $excel-filtering-subheader-foreground [null] - The text color of the sorting and moving headers in the excel style filtering.
/// @param {Color} $excel-filtering-actions-foreground [null] - The text color of the excel style filtering options.
/// @param {Color} $excel-filtering-actions-hover-foreground [null] - The text color of the excel style filtering options in hover/focus state.
/// @param {Color} $excel-filtering-actions-disabled-foreground [null] - The text color of the excel style filtering options in disabled state.
/// @param {Color} $tree-filtered-text-color [null] - grouping row background color on focus.
/// @param {Color} $body-summaries-background [null] - The background color of the summary groups located the body.
/// @param {Color} $body-summaries-text-color [null] - The text color of the summary results located the body.
/// @param {Color} $root-summaries-background [null] - The background color of the summary groups located the footer.
/// @param {Color} $root-summaries-text-color [null] - The text color of the summary results located the footer.
/// @param {Color} $row-highlight [null] - The grid row highlight indication color.
/// @param {box-shadow} $grid-shadow [null] - The shadow of the grid.
/// @param {box-shadow} $drag-shadow [null] - The shadow used for movable elements (ex. column headers).
/// @param {color} $row-ghost-background [null] - The dragged row background color.
/// @param {color} $row-drag-color [null] - The row drag handle color.
/// @param {Color} $grid-border-color [null] - The color of the grid border.
/// @param {List} $drop-area-border-radius [null] - The border radius used for column drop area.
/// @requires $light-material-schema
/// @example scss Change the header background color
///   $my-grid-theme: grid-theme($header-background: black);
///   // Pass the theme to the css-vars() mixin
///   @include css-vars($my-grid-theme);
@function grid-theme(
    $schema: $light-material-schema,
    $elevations: null,

    $header-background: null,
    $header-text-color: null,
    $header-border-width: null,
    $header-border-style: null,
    $header-border-color: null,

    $header-selected-background: null,
    $header-selected-text-color: null,

    $sorted-header-icon-color: null,

    $content-background: null,
    $content-text-color: null,

    $ghost-header-text-color: null,
    $ghost-header-icon-color: null,
    $ghost-header-background: null,

    $row-odd-background: null,
    $row-even-background: null,
    $row-odd-text-color: null,
    $row-even-text-color: null,
    $row-selected-background: null,
    $row-selected-hover-background: null,
    $row-selected-text-color: null,
    $row-selected-hover-text-color: null,
    $row-hover-background: null,
    $row-hover-text-color: null,
    $row-border-color: null,

    $pinned-border-width: null,
    $pinned-border-style: null,
    $pinned-border-color: null,

    $cell-active-border-color: null,
    $cell-selected-background: null,
    $cell-selected-text-color: null,
    $cell-editing-background: null,
    $cell-editing-foreground: null,
    $cell-editing-focus-foreground: null,
    $cell-edited-value-color: null,
    $cell-new-color: null,
    $cell-disabled-color: null,

    $edit-mode-color: null,
    $edited-row-indicator: null,

    $resize-line-color: null,
    $drop-indicator-color: null,

    $grouparea-background: null,
    $grouparea-color: null,

    $group-row-background: null,
    $group-row-selected-background: null,
    $group-label-column-name-text: null,
    $group-label-icon: null,
    $group-label-text: null,

    $expand-all-icon-color: null,
    $expand-all-icon-hover-color: null,

    $expand-icon-color: null,
    $expand-icon-hover-color: null,

    $active-expand-icon-color: null,
    $active-expand-icon-hover-color: null,

    $group-count-background: null,
    $group-count-text-color: null,

    $drop-area-text-color: null,
    $drop-area-icon-color: null,
    $drop-area-background: null,
    $drop-area-on-drop-background: null,

    $filtering-header-background: null,
    $filtering-header-text-color: null,
    $filtering-row-background: null,
    $filtering-row-text-color: null,
    $filtering-background-and: null,
    $filtering-background-or: null,
    $filtering-background-and--focus: null,
    $filtering-background-or--focus: null,
    $tree-filtered-text-color: null,
    $tree-selected-filtered-row-text-color: null,
    $tree-selected-filtered-cell-text-color: null,

    $excel-filtering-header-foreground: null,
    $excel-filtering-subheader-foreground: null,
    $excel-filtering-actions-foreground: null,
    $excel-filtering-actions-hover-foreground: null,
    $excel-filtering-actions-disabled-foreground: null,

    $body-summaries-background: null,
    $body-summaries-text-color: null,
    $root-summaries-background: null,
    $root-summaries-text-color: null,

    $row-highlight: null,
    $grid-shadow: null,
    $drag-shadow: null,
    $row-ghost-background: null,
    $row-drag-color: null,
    $drop-area-border-radius: null,
    $grid-border-color: null,
    $sortable-header-icon-hover-color: null,

    $cell-selected-within-background: null,
    $cell-selected-within-text-color: null,
) {
    $name: 'igx-grid';
    $selector: 'igx-grid, igx-hierarchical-grid, igx-pivot-grid, igx-tree-grid, igx-advanced-filtering-dialog, igx-grid-excel-style-filtering, .igx-excel-filter__secondary';
    $grid-schema: ();

    @if map.has-key($schema, 'grid') {
        $grid-schema: map.get($schema, 'grid');
    } @else {
        $grid-schema: $schema;
    }

    $theme: digest-schema($grid-schema);
    $meta: map.get($theme, '_meta');

    @if not($cell-selected-within-text-color) and $cell-selected-within-background {
        $cell-selected-within-text-color: text-contrast($cell-selected-within-background);
    }

    @if not($ghost-header-icon-color) and $ghost-header-background {
        @if meta.type-of($ghost-header-background) == 'color' {
            $ghost-header-icon-color: rgba(text-contrast($ghost-header-background), .07);
        }
    }

    @if not($ghost-header-text-color) and $ghost-header-background {
        $ghost-header-text-color: text-contrast($ghost-header-background);
    }

    @if $header-background and meta.type-of($header-background) == 'color' {
        $header-background: to-opaque($header-background, color($color: 'surface'));
    }

    @if not($header-text-color) and $header-background {
        $header-text-color: text-contrast($header-background);
    }

    @if not($header-selected-text-color) and $header-selected-background {
        $header-selected-text-color: text-contrast($header-selected-background);
    }

    @if not($header-border-color) and $header-background {
        @if meta.type-of($header-background) == 'color' {
            $header-border-color: rgba(text-contrast($header-background), .24);
        }
    }

    @if not($content-text-color) and $content-background {
        $content-text-color: text-contrast($content-background);
    }

    @if not($row-odd-background) and $content-background {
        $row-odd-background: $content-background;
    }

    @if $row-odd-background and meta.type-of($row-odd-background) == 'color' and color.alpha($row-odd-background) != 1 {
        $cbg: if($content-background, $content-background, map.get($theme, 'content-background'));

        $row-odd-background: to-opaque($row-odd-background, $cbg);
    }

    @if not($row-odd-text-color) and $row-odd-background {
        $row-odd-text-color: text-contrast($row-odd-background);
    }

    @if not($row-even-background) and $content-background {
        $row-even-background: $content-background;
    }

    @if $row-even-background and meta.type-of($row-even-background) == 'color' and color.alpha($row-even-background) != 1 {
        $cbg: if($content-background, $content-background, map.get($theme, 'content-background'));

        $row-even-background: to-opaque($row-even-background, $cbg);
    }

    @if not($row-even-text-color) and $row-even-background {
        $row-even-text-color: text-contrast($row-even-background);
    }

    @if not($row-hover-background) and $content-background {
        @if meta.type-of($content-background) == 'color' {
            $row-hover-background: to-opaque(rgba(text-contrast($content-background), .08), $content-background)
        }
    }

    @if not($row-hover-text-color) and $row-hover-background {
        $row-hover-text-color: text-contrast($row-hover-background);
    }

    @if not($cell-selected-text-color) and $cell-selected-background {
        $cell-selected-text-color: text-contrast($cell-selected-background);
    }

    @if not($row-selected-text-color) and $row-selected-background {
        $row-selected-text-color: text-contrast($row-selected-background);
    }

    @if not($row-selected-hover-text-color) and $row-selected-hover-background {
        $row-selected-hover-text-color: text-contrast($row-selected-hover-background);
    }

    @if not($row-border-color) and $content-background {
        @if meta.type-of($content-background) == 'color' {
            $row-border-color: rgba(text-contrast($content-background), .08)
        }
    }

    @if not($pinned-border-color) and $content-background {
        @if meta.type-of($content-background) == 'color' {
            $pinned-border-color: rgba(text-contrast($content-background), .08)
        }
    }

    @if not($group-row-background) and $header-background {
        $group-row-background: $header-background
    }

    @if not($expand-icon-color) and $group-row-background {
        $expand-icon-color: text-contrast($group-row-background)
    }

    @if not($group-label-text) and $group-row-selected-background {
        $group-label-text: text-contrast($group-row-selected-background)
    }

    @if not($expand-icon-color) and $group-row-selected-background {
        $expand-icon-color: text-contrast($group-row-selected-background)
    }

    @if not($group-count-background) and $group-row-selected-background {
        $group-count-background: text-contrast($group-row-selected-background);
    }

    @if not($expand-all-icon-color) and $header-background {
        @if meta.type-of($header-background) == 'color' {
            $expand-all-icon-color: rgba(text-contrast($header-background), .87);
        }
    }

    @if not($expand-all-icon-hover-color) and $header-background {
        $expand-all-icon-hover-color: text-contrast($header-background);
    }

    @if not($group-label-text) and $group-row-background {
        $group-label-text: text-contrast($group-row-background)
    }

    @if not($group-count-background) and $group-row-background {
        $group-count-background: text-contrast($group-row-background);
    }

    @if not($group-count-text-color) and $group-count-background {
        $group-count-text-color: text-contrast($group-count-background);
    }

    @if not($grouparea-background) and $header-background {
        $grouparea-background: $header-background
    }

    @if not($grouparea-color) and $grouparea-background {
        @if meta.type-of($grouparea-background) == 'color' {
            $grouparea-color: rgba(text-contrast($grouparea-background), .8)
        }
    }

    @if not($grouparea-color) and $header-background {
        @if meta.type-of($header-background) == 'color' {
            $grouparea-color: rgba(text-contrast($header-background), .8)
        }
    }

    @if not($drop-area-background) and $grouparea-background {
        $drop-area-background: text-contrast($grouparea-background);
    }

    @if not($drop-area-on-drop-background) and $drop-area-background {
        $drop-area-on-drop-background: $drop-area-background;
    }

    @if not($drop-area-text-color) and $drop-area-background {
        $drop-area-text-color: text-contrast($drop-area-background)
    }

    @if not($drop-area-icon-color) and $drop-area-background {
        $drop-area-icon-color: text-contrast($drop-area-background)
    }

    @if not($filtering-header-background) and $header-background {
        @if meta.type-of($header-background) == 'color' {
            @if luminance($header-background) < .5 {
                $filtering-header-background: to-opaque(color.scale($header-background, $lightness: 5%));
            } @else {
                $filtering-header-background: to-opaque(color.scale($header-background, $lightness: -5%));
            }
        }
    }

    @if not($filtering-header-text-color) and $filtering-header-background {
        $filtering-header-text-color: text-contrast($filtering-header-background);
    }

    @if not($filtering-row-background) and $header-background {
        @if meta.type-of($header-background) == 'color' {
            @if luminance($header-background) < .5 {
                $filtering-row-background: to-opaque(color.scale($header-background, $lightness: 5%));
            } @else {
                $filtering-row-background: to-opaque(color.scale($header-background, $lightness: -5%));
            }
        }
    }

    @if not($filtering-row-text-color) and $filtering-row-background {
        $filtering-row-text-color: text-contrast(to-opaque($filtering-row-background));
    }

    @if not($body-summaries-text-color) and $body-summaries-background {
        $body-summaries-text-color: text-contrast($body-summaries-background);
    }

    @if not($root-summaries-text-color) and $root-summaries-background {
        $root-summaries-text-color: text-contrast($root-summaries-background);
    }

    @if not($grid-shadow) {
        $grid-elevation: map.get($grid-schema, 'grid-elevation');
        $grid-shadow: elevation($grid-elevation);
    }

    @if not($drag-shadow) {
        $drag-elevation: map.get($grid-schema, 'drag-elevation');
        $drag-shadow: elevation($drag-elevation);
    }

    @if not($drop-area-border-radius) {
        $drop-area-border-radius: map.get($theme, 'drop-area-border-radius');
    }

    @return extend($theme, (
        name: $name,
        selector: $selector,

        header-background: $header-background,
        header-text-color: $header-text-color,
        header-selected-background:  $header-selected-background,
        header-selected-text-color:  $header-selected-text-color,
        header-border-width: $header-border-width,
        header-border-style: $header-border-style,
        header-border-color: $header-border-color,

        sorted-header-icon-color: $sorted-header-icon-color,

        ghost-header-text-color: $ghost-header-text-color,
        ghost-header-icon-color: $ghost-header-icon-color,
        ghost-header-background: $ghost-header-background,

        content-background: $content-background,
        content-text-color: $content-text-color,

        row-odd-background: $row-odd-background,
        row-even-background: $row-even-background,
        row-odd-text-color: $row-odd-text-color,
        row-even-text-color: $row-even-text-color,
        row-selected-background: $row-selected-background,
        row-selected-hover-background: $row-selected-hover-background,
        row-selected-text-color: $row-selected-text-color,
        row-selected-hover-text-color: $row-selected-hover-text-color,
        row-hover-background: $row-hover-background,
        row-hover-text-color: $row-hover-text-color,
        row-border-color: $row-border-color,

        pinned-border-width: $pinned-border-width,
        pinned-border-style: $pinned-border-style,
        pinned-border-color: $pinned-border-color,

        cell-active-border-color: $cell-active-border-color,
        cell-selected-background: $cell-selected-background,
        cell-editing-background: $cell-editing-background,
        cell-editing-foreground: $cell-editing-foreground,
        cell-editing-focus-foreground: $cell-editing-focus-foreground,
        cell-selected-text-color: $cell-selected-text-color,

        edit-mode-color: $edit-mode-color,
        edited-row-indicator: $edited-row-indicator,
        cell-edited-value-color: $cell-edited-value-color,
        cell-new-color: $cell-new-color,

        cell-disabled-color: $cell-disabled-color,

        resize-line-color: $resize-line-color,

        drop-indicator-color: $drop-indicator-color,

        grouparea-background: $grouparea-background,
        grouparea-color: $grouparea-color,

        group-label-column-name-text: $group-label-column-name-text,
        group-label-icon: $group-label-icon,
        group-label-text: $group-label-text,

        expand-all-icon-color: $expand-all-icon-color,
        expand-all-icon-hover-color: $expand-all-icon-hover-color,

        expand-icon-color: $expand-icon-color,
        expand-icon-hover-color: $expand-icon-hover-color,
        active-expand-icon-color: $active-expand-icon-color,
        active-expand-icon-hover-color: $active-expand-icon-hover-color,

        group-count-background: $group-count-background,
        group-count-text-color: $group-count-text-color,

        group-row-background: $group-row-background,
        group-row-selected-background: $group-row-selected-background,

        drop-area-text-color: $drop-area-text-color,
        drop-area-icon-color: $drop-area-icon-color,
        drop-area-on-drop-background: $drop-area-on-drop-background,
        drop-area-background: $drop-area-background,

        filtering-header-background: $filtering-header-background,
        filtering-header-text-color: $filtering-header-text-color,
        filtering-row-background: $filtering-row-background,
        filtering-row-text-color: $filtering-row-text-color,
        filtering-background-and: $filtering-background-and,
        filtering-background-or: $filtering-background-or,
        filtering-background-and--focus: $filtering-background-and--focus,
        filtering-background-or--focus: $filtering-background-or--focus,

        excel-filtering-header-foreground: $excel-filtering-header-foreground,
        excel-filtering-subheader-foreground: $excel-filtering-subheader-foreground,
        excel-filtering-actions-foreground: $excel-filtering-actions-foreground,
        excel-filtering-actions-hover-foreground: $excel-filtering-actions-hover-foreground,
        excel-filtering-actions-disabled-foreground: $excel-filtering-actions-disabled-foreground,

        tree-filtered-text-color: $tree-filtered-text-color,
        tree-selected-filtered-row-text-color: $tree-selected-filtered-row-text-color,
        tree-selected-filtered-cell-text-color: $tree-selected-filtered-cell-text-color,

        body-summaries-background: $body-summaries-background,
        body-summaries-text-color: $body-summaries-text-color,
        root-summaries-background: $root-summaries-background,
        root-summaries-text-color: $root-summaries-text-color,

        row-highlight: $row-highlight,
        grid-shadow: $grid-shadow,
        drag-shadow: $drag-shadow,
        row-ghost-background: $row-ghost-background,
        row-drag-color: $row-drag-color,
        drop-area-border-radius: $drop-area-border-radius,
        sortable-header-icon-hover-color: $sortable-header-icon-hover-color,
        grid-border-color: $grid-border-color,

        cell-selected-within-background: $cell-selected-within-background,
        cell-selected-within-text-color: $cell-selected-within-text-color,

        _meta: map.merge(if($meta, $meta, ()), (
            variant: map.get($schema, '_meta', 'theme'),
            theme-variant: map.get($schema, '_meta', 'variant')
        )),
    ));
}

@mixin _filtering-scroll-mask($theme, $dir) {
    display: block;
    position: absolute;
    width: rem(10px);
    content: '';
    inset-block: rem(-2px);
    background: linear-gradient(to #{$dir}, var-get($theme, 'filtering-row-background'), transparent);
}

/// @deprecated Use the `css-vars` mixin instead.
/// @see {mixin} css-vars
/// @param {Map} $theme - The theme used to style the component.
/// @requires {mixin} excel-filtering
@mixin grid($theme) {
    @include css-vars($theme);

    @include scale-in-ver-center();

    $variant: map.get($theme, '_meta', 'variant');
    $theme-variant: map.get($theme, '_meta', 'theme-variant');
    $bootstrap-theme: $variant == 'bootstrap';
    $not-bootstrap-theme: $variant != 'bootstrap';

    $cbx-size: map.get((
        material: rem(20px),
        fluent: rem(20px),
        bootstrap: rem(14px),
        indigo-design: rem(20px),
    ), $variant);
    $cbx-bs-size: rem(14px);

    $grid-shadow: var-get($theme, 'grid-shadow');

    $grid-caption-fs: rem(20px);
    $grid-caption-lh: rem(32px);
    $grid-caption-padding: rem(16px) rem(24px);

    $grid-head-fs: rem(12px);
    $grid-head-fw: 600;
    $transition: all 120ms $ease-in-out-cubic;

    // Cell
    $grid-cell-align-num: end;
    $grid-cell-fs: rem(13px);
    $grid-cell-lh: rem(16px);
    $grid-cell-pinned-style: rem(1px) solid;
    $grid-cell-pinned-border-color: color($color: 'gray', $variant: 300);

    $grid-header-border: var-get($theme, 'header-border-width') var-get($theme, 'header-border-style') var-get($theme, 'header-border-color');

    $cell-pin: (
        style: var-get($theme, 'pinned-border-width') var-get($theme, 'pinned-border-style'),
        color: var-get($theme, 'pinned-border-color')
    );

    $padding-comfortable: rem(24px);
    $padding-cosy: rem(16px);
    $padding-compact: rem(12px);

    $grid-header-padding-inline: (
        comfortable: $padding-comfortable,
        cosy: $padding-cosy,
        compact: $padding-compact
    );

    $pivot-row-aria-padding: (
        comfortable: $padding-comfortable,
        cosy: $padding-cosy,
        compact: $padding-compact
    );

    $grid-cbx-padding: (
        comfortable: $padding-comfortable,
        cosy: $padding-cosy,
        compact: $padding-compact
    );

    $cbx-padding: map.get($grid-cbx-padding, 'comfortable');
    $cbx-padding-cosy: map.get($grid-cbx-padding, 'cosy');
    $cbx-padding-compact: map.get($grid-cbx-padding, 'compact');

    $grid-header-height: (
        comfortable: rem(50px),
        cosy: rem(40px),
        compact: rem(32px)
    );

    $drop-area-height: (
        comfortable: rem(32px),
        cosy: rem(24px),
        compact: rem(24px)
    );

    $cell-padding-comfortable: rem(24px);
    $cell-padding-cosy: rem(16px);
    $cell-padding-compact: rem(12px);

    $grid-cell-padding-inline: (
        comfortable: $cell-padding-comfortable,
        cosy: $cell-padding-cosy,
        compact: $cell-padding-compact
    );

    $hierarchical-grid-indent: (
        comfortable: rem(24px),
        cosy: rem(16px),
        compact: rem(12px)
    );

    $hierarchical-action-icon: rem(24px);

    $hierarchical-indent: (
        comfortable: calc(2 * #{map.get($hierarchical-grid-indent, 'comfortable')} + #{$hierarchical-action-icon}),
        cosy: calc(2 * #{map.get($hierarchical-grid-indent, 'cosy')} + #{$hierarchical-action-icon}),
        compact: calc(2 * #{map.get($hierarchical-grid-indent, 'compact')} + #{$hierarchical-action-icon})
    );

    $hierarchical-indent-scroll: (
        comfortable: calc(#{map.get($hierarchical-grid-indent, 'comfortable')} + 18px),
        cosy: calc(#{map.get($hierarchical-grid-indent, 'cosy')} + 18px),
        compact: calc(#{map.get($hierarchical-grid-indent, 'compact')} + 18px)
    );

    $grouparea-padding-inline: (
        comfortable: rem(24px),
        cosy: rem(16px),
        compact: rem(12px)
    );

    $grouparea-min-height: (
        comfortable: rem(57px),
        cosy: rem(49px),
        compact: rem(41px)
    );

    $grid-grouping-indicator-padding: (
        comfortable: rem(24px),
        cosy: rem(16px),
        compact: rem(12px)
    );

    $indicator-icon-width: rem(24px);
    $drag-icon-size: rem(24px);
    $grouping-padding-right: rem(12px);

    $grid-header-weight: map.get((
        material: 600,
        fluent: 800,
        bootstrap: 700,
        indigo-design: 600,
    ), $variant);

    igx-grid,
    igx-hierarchical-grid,
    igx-pivot-grid,
    igx-tree-grid {
        @if $variant == 'material' {
            @if $theme-variant == 'light' {
                --igx-chip-disabled-text-color: #{color($color: 'gray', $variant: 500)};
                --igx-chip-disabled-background: #{color($color: 'gray', $variant: 300)};
                --igx-chip-disabled-border-color: #{color($color: 'gray', $variant: 300)};
            }
            @if $theme-variant == 'dark' {
                --igx-chip-disabled-text-color: #{color($color: 'gray', $variant: 300)};
                --igx-chip-disabled-background: #{color($color: 'gray', $variant: 200)};
                --igx-chip-disabled-border-color: #{color($color: 'gray', $variant: 200)};
            }
        }

        @if $variant == 'fluent' {
            @if $theme-variant == 'dark' {
                --igx-chip-disabled-text-color: #{color($color: 'gray', $variant: 400)};
                --igx-chip-disabled-background: #{color($color: 'gray', $variant: 200)};
                --igx-chip-disabled-border-color: #{color($color: 'gray', $variant: 200)};
            }
        }

        @if $variant == 'bootstrap' {
            @if $theme-variant == 'dark' {
                --igx-chip-disabled-text-color: #{color($color: 'gray', $variant: 400)};
                --igx-chip-disabled-background: #{color($color: 'gray', $variant: 200)};
                --igx-chip-disabled-border-color: #{color($color: 'gray', $variant: 200)};
            }
        }

        @if $variant == 'indigo-design' {
            @if $theme-variant == 'light' {
                --igx-chip-disabled-text-color: #{color($color: 'gray', $variant: 500)};
                --igx-chip-disabled-background: #{color($color: 'gray', $variant: 200)};
                --igx-chip-disabled-border-color: #{color($color: 'gray', $variant: 300)};
            }
            @if $theme-variant == 'dark' {
                --igx-chip-disabled-text-color: #{color($color: 'gray', $variant: 300)};
                --igx-chip-disabled-background: #{color($color: 'gray', $variant: 200)};
                --igx-chip-disabled-border-color: #{color($color: 'gray', $variant: 200)};
            }
        }
    }

    %disable-focus-styles {
        outline: 0;
    }

    %grid-host {
        @include sizable();

        --component-size: var(--ig-size, var(--ig-size-large));
        --grid-size: var(--component-size);
    }

    %grid-display {
        --header-size: #{sizable(
            map.get($grid-header-height, 'compact'),
            map.get($grid-header-height, 'cosy'),
            map.get($grid-header-height, 'comfortable')
        )};
        --grouparea-size: #{sizable(
            map.get($grouparea-min-height, 'compact'),
            map.get($grouparea-min-height, 'cosy'),
            map.get($grouparea-min-height, 'comfortable')
        )};
        --igx-tree-indent-size: #{sizable(rem(12px), rem(16px), rem(24px))};

        position: relative;
        display: grid;
        grid-template-rows: auto auto auto 1fr auto auto;
        grid-template-columns: 100%;
        overflow: hidden;
        box-shadow: $grid-shadow;

        @if $variant == 'fluent' {
            box-shadow: 0 0 0 rem(1px) var-get($theme, 'grid-border-color');
        }

        outline-style: none;
        z-index: 1;

        %cbx-display {
            min-width: $cbx-size;
        }
    }

    %grid-caption {
        display: flex;
        align-items: center;
        font-size: $grid-caption-fs;
        line-height: $grid-caption-lh;
        padding: $grid-caption-padding;
        grid-row: 1;
    }

    %grid-thead,
    %grid-tfoot {
        position: relative;
        display: flex;
        background: var-get($theme, 'header-background');
        color: var-get($theme, 'header-text-color');
        overflow: hidden;
        outline-style: none;

        %grid-row {
            position: relative;
            background: inherit;
            color: inherit;
            z-index: 2;

            &:hover {
                background: inherit;
                color: inherit;
            }
        }

        > [aria-activedescendant] {
            outline-style: none;
        }
    }

    %grid-thead {
        border-bottom: $grid-header-border;

        @if $bootstrap-theme {
            border-bottom-width: rem(2px);
        }

        z-index: 2;

        %grid__cbx-selection--push {
            align-items: flex-start;
            padding-block-start: pad-block(
                math.div(map.get($grid-header-height, 'compact') - rem(20px), 2),
                math.div(map.get($grid-header-height, 'cosy') - rem(20px), 2),
                math.div(map.get($grid-header-height, 'comfortable') - rem(20px), 2)
            );
        }

        %grid-row {
            border-bottom: none;
        }
    }

    %grid-thead-container {
        grid-row: 3;
        display: flex;
        overflow: hidden;

        %igx-grid__header-indentation {
            igx-icon {
                --component-size: var(--ig-size, var(--ig-size-large));
                font-size: rem(24px);
                width: rem(24px);
                height: rem(24px);
            }
        }

        %igx-grid__drag-indicator {
            cursor: default;
        }

        %grid-row--mrl {
            %igx-grid__hierarchical-expander--header,
            %igx-grid__header-indentation,
            %igx-grid__row-indentation,
            %grid__cbx-selection {
                border-bottom: var-get($theme, 'header-border-width') var-get($theme, 'header-border-style') var-get($theme, 'header-border-color');
            }
        }
    }

    %grid-thead-title {
        flex-basis: auto !important;
        align-items: center !important;
        border-bottom: $grid-header-border;
        height: var(--header-size);
        padding-inline: pad-inline(
            map.get($grid-cell-padding-inline, 'compact'),
            map.get($grid-cell-padding-inline, 'cosy'),
            map.get($grid-cell-padding-inline, 'comfortable')
        );
        padding-block: 0;
    }

    %grid-thead-title--pinned {
        border-inline-end: map.get($cell-pin, 'style') map.get($cell-pin, 'color') !important;
    }

    %grid-thead-group {
        display: flex;
        flex-flow: row nowrap;
    }

    /* We set those with position relative
    so that the drop indicators be scoped
    to their respective group. The item
    being the topmost element, while the
    subgroup encapsulates children of each
    thead item and group.
    */
    %grid-thead-item {
        display: flex;
        flex-flow: column nowrap;

        %grid-thead-group {
            flex: 1 1 auto;
        }

        %grid-cell-header {
            flex: 1 1 auto;
        }

        %grid-thead-title {
            flex: 0 0 auto;
        }
    }

    %grid-thead-item,
    %grid-thead-subgroup {
        position: relative;
    }

    %grid-tfoot {
        grid-row: 5;
        border-top: $grid-header-border;
        z-index: 10001;
    }

    %grid-footer {
        grid-row: 7;
    }

    %grid-display-container-thead {
        width: 100%;
        overflow: visible;
    }

    %grid-display-container-tr {
        width: 100%;
        overflow: visible;
    }

    %grid-mrl-block {
        display: grid;
        background: inherit;
        position: relative;

        %grid-thead-item {
            display: flex;
        }

        %grid-cell-header {
            align-items: center;
            flex-grow: 1;
            border-bottom: $grid-header-border;
        }

        %grid-cell-display {
            border-inline-end: rem(1px) solid var-get($theme, 'row-border-color');
            border-bottom: rem(1px) solid var-get($theme, 'row-border-color');
        }
    }

    %grid-row--mrl {
        &%grid-row {
            border-bottom-color: transparent;
        }

        %grid__cbx-selection,
        %igx-grid__row-indentation,
        %igx-grid__drag-indicator {
            border-bottom: rem(1px) solid var-get($theme, 'row-border-color');
        }
    }

    %grid-tbody {
        position: relative;
        background: var-get($theme, 'content-background');
        color: var-get($theme, 'content-text-color');
        overflow: hidden;
        z-index: 1;
        outline-style: none;
    }

    %grid-tbody-container {
        position: relative;
        display: flex;
        grid-row: 4;
        overflow: hidden;
    }

    %grid-tbody-message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: var-get($theme, 'content-text-color');
        flex-direction: column;
        padding: rem(24px);
    }

    %igx-grid__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: rem(100px);

        > %circular-display {
            width: rem(50);
            height: rem(50);
        }
    }

    %grid-scroll {
        grid-row: 6;
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        background: var-get($theme, 'header-background');
        z-index: 10001;
    }

    %grid-thead-thumb {
        background: var-get($theme, 'header-background');
        border-inline-start: rem(1px) solid var-get($theme, 'header-border-color');
    }

    %grid-tfoot-thumb {
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        background: var-get($theme, 'header-background');
        border-inline-start: rem(1px) solid var-get($theme, 'header-border-color');
    }

    %grid-tbody-scrollbar {
        background: var-get($theme, 'content-background');
        border-inline-start: rem(1px) solid var-get($theme, 'row-border-color');
        position: relative;
    }

    %grid-tbody-scrollbar-start {
        background: var-get($theme, 'header-background');
    }

    %grid-tbody-scrollbar-main {
        position: relative;
    }

    %grid-tbody-scrollbar-end {
        background: var-get($theme, 'header-background');
    }

    %grid-scroll-start {
        background: var-get($theme, 'header-background');
    }

    %grid-scroll-main {
        igx-display-container {
            height: 0;
        }

        igx-horizontal-virtual-helper {
            height: 100%;
        }
    }

    %grid-row {
        display: flex;
        background: var-get($theme, 'content-background');
        border-bottom: rem(1px) solid var-get($theme, 'row-border-color');
        outline-style: none;
        position: relative;
        background-clip: content-box !important;

        &:hover {
            background: var-get($theme, 'row-hover-background');
            color: var-get($theme, 'row-hover-text-color');

            %grid-cell--column-selected {
                color: var-get($theme, 'row-selected-hover-text-color');
                background: var-get($theme, 'row-selected-hover-background');
            }

            %grid-cell--cross-selected {
                color: var-get($theme, 'cell-selected-within-text-color');
                background: var-get($theme, 'cell-selected-within-background');
            }
        }

        &%igx-grid__tr--ghost {
            @include css-vars((
                name: 'igx-grid-row',
                row-ghost-background: map.get($theme, 'row-ghost-background'),
                row-drag-color: map.get($theme, 'row-drago-color')
            ));

            background: var-get($theme, 'row-ghost-background');
            color: var-get($theme, 'row-drag-color');
            z-index: 10002;
        }
    }

    %igx-grid__drag-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: pad-inline(
            map.get($grid-cell-padding-inline, 'compact'),
            map.get($grid-cell-padding-inline, 'cosy'),
            map.get($grid-cell-padding-inline, 'comfortable')
        );
        padding-block: 0;
        flex: 1 0 auto;
        // Fix for IE 11
        min-width: calc(#{$drag-icon-size} + #{$cell-padding-comfortable});
        background: inherit;
        z-index: 4;
        cursor: move;
        border-inline-end: rem(1px) solid transparent;
        background-clip: border-box;

        igx-icon {
            width: var(--igx-icon-size, #{$drag-icon-size});
            height: var(--igx-icon-size, #{$drag-icon-size});
            font-size: var(--igx-icon-size, #{$drag-icon-size});
        }
    }

    %igx-grid__drag-indicator--header {
        border-inline-end: $grid-header-border;
    }

    %igx-grid__drag-indicator--off {
        color: var-get($theme, 'row-drag-color');
    }

    %igx-grid__tr--drag {
        opacity: .5;
    }

    %grid-row--odd {
        background: var-get($theme, 'row-odd-background');
        color: var-get($theme, 'row-odd-text-color');
    }

    %grid-row--even {
        background: var-get($theme, 'row-even-background');
        color: var-get($theme, 'row-even-text-color');
    }

    %igx-grid__tr--expanded {
        border-bottom: none;
    }

    %igx-grid__tr--pinned {
        position: relative;
        background: inherit;
        z-index: 10000;

        %igx-grid__hierarchical-expander--empty {
            border-inline-end: rem(1px) solid var-get($theme, 'header-border-color');
        }
    }

    %igx-grid__tr--pinned-top {
        border-bottom: map.get($cell-pin, 'style') map.get($cell-pin, 'color') !important;
    }

    %igx-grid__tr--pinned-bottom {
        border-top: map.get($cell-pin, 'style') map.get($cell-pin, 'color') !important;
        position: absolute;
        bottom: 0;
    }

    %igx-grid__td--centered {
        justify-content: center;
    }

    %igx-grid__td--bool {
        igx-icon {
            width: var(--igx-icon-size, #{rem(18px)});
            height: var(--igx-icon-size, #{rem(18px)});
            font-size: var(--igx-icon-size, #{rem(18px)});
        }

        %igx-icon--error {
            color: color($color: 'gray', $variant: 500);
        }
    }

    %igx-grid__td--bool-true {
        %igx-icon--success {
            color: color($color: 'gray', $variant: 700);
        }
    }

    %igx-grid__tr--edit {
        border-bottom: rem(1px) solid var-get($theme, 'edit-mode-color');
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: rem(1);
            width: 100%;
            top: rem(-1);
            inset-inline-start: 0;
            background: var-get($theme, 'edit-mode-color');
        }

        &%grid-row {
            border-bottom: rem(1px) solid var-get($theme, 'edit-mode-color');
        }

        %igx-grid__td--editing {
            border: none;

            %form-group-bundle--focus {
                caret-color: var-get($theme, 'edit-mode-color') !important;
            }

            %form-group-bundle--fluent--focus {
                &::after {
                    border: none !important;
                }
            }

            %form-group-border {
                background: var-get($theme, 'edit-mode-color') !important;
            }
        }

        [aria-readonly='true'] {
            color: var-get($theme, 'cell-disabled-color');

            igx-icon {
                color: var-get($theme, 'cell-disabled-color');
            }
        }
    }

    %igx-grid__tr--inner {
        display: flex;
        background: inherit;
    }

    %igx-grid__tr--header {
        display: flex;
        align-items: center;
    }

    %igx-grid__tr--add-animate {
        @include animation(scale-in-ver-center .2s $ease-in-out-quad);
    }

    %grid-row--edit-mrl {
        &:first-of-type::after {
            top: 0;
            z-index: 5;
        }
    }

    %igx-grid__tr--edited {
        &::before {
            content: '';
            position: absolute;
            width: if($variant == 'indigo-design', rem(4px), rem(2px));
            height: 100%;
            z-index: 10000;
            background: var-get($theme, 'edited-row-indicator');
        }
    }

    %grid-row--group {
        position: relative;
        background: var-get($theme, 'header-background') !important;
    }

    %igx-grid-row--filtered {
        %grid-cell-text {
            color: var-get($theme, 'tree-filtered-text-color');
        }

        %igx-grid__tree-grouping-indicator {
            color: var-get($theme, 'tree-filtered-text-color');

            &:hover {
                color: var-get($theme, 'tree-filtered-text-color');
            }
        }

        %grid-cell--selected {
            %grid-cell-text {
                color: var-get($theme, 'tree-selected-filtered-cell-text-color');
            }

            %igx-grid__tree-grouping-indicator {
                color: var-get($theme, 'tree-selected-filtered-cell-text-color');

                &:hover {
                    color: var-get($theme, 'tree-selected-filtered-cell-text-color');
                }
            }
        }
    }

    %grid-row--selected--filtered {
        %grid-cell-text {
            color: var-get($theme, 'tree-selected-filtered-row-text-color');
        }

        %igx-grid__tree-grouping-indicator {
            color: var-get($theme, 'tree-selected-filtered-row-text-color');

            &:hover {
                color: var-get($theme, 'tree-selected-filtered-row-text-color');
            }
        }

        %grid-cell--selected {
            %grid-cell-text {
                color: var-get($theme, 'tree-selected-filtered-cell-text-color');
            }

            %igx-grid__tree-grouping-indicator {
                color: var-get($theme, 'tree-selected-filtered-cell-text-color');

                &:hover {
                    color: var-get($theme, 'tree-selected-filtered-cell-text-color');
                }
            }
        }
    }

    %igx-grid__tree-grouping-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        outline-style: none;
        margin-inline-end: rem(8);
        cursor: pointer;

        color: var-get($theme, 'expand-icon-color');

        &:hover {
            color: var-get($theme, 'expand-icon-hover-color')
        }

        [dir='rtl'] & {
            transform: scaleX(-1);
        }

        igx-icon {
            --component-size: 3;
        }
    }

    %igx-grid__tree-loading-indicator {
        width: rem(24px, 16px);
        height: rem(24px, 16px);
        margin-inline-end: rem(8px, 16px);

        %circular-outer {
            stroke: var-get($theme, 'expand-icon-color');
        }

        > %circular-display {
            width: rem(24);
            height: rem(24);
        }
    }

    %grid-cell-display {
        position: relative;
        display: flex;
        flex: 1 1 0%;
        align-items: center;
        outline-style: none;
        padding-inline: pad-inline(
            map.get($grid-cell-padding-inline, 'compact'),
            map.get($grid-cell-padding-inline, 'cosy'),
            map.get($grid-cell-padding-inline, 'comfortable')
        );
        padding-block: 0;
        font-size: $grid-cell-fs;
        line-height: $grid-cell-lh;
        color: inherit;
        text-align: start;
        background-clip: border-box !important;
        min-height: sizable(
            rem(32px),
            rem(40px),
            rem(50px)
        );
    }

    // This is no longer being extended and is left
    // here for reference purposes only. It seems setting
    // overflow: hidden on the cell prevents drag and selection
    // of the cell.
    // See github issue #9821
    %igx-grid__td--tree-cell {
        overflow: hidden;
    }

    %grid-cell-text {
        @include ellipsis();

        pointer-events: none;
    }

    %grid-cell--fixed-width {
        flex-grow: 0;
        outline-style: none;
    }

    %grid-cell--active {
        box-shadow: inset 0 0 0 rem(1px) var-get($theme, 'cell-active-border-color');

        %igx-grid__filtering-cell,
        %grid-cell-header {
            border-inline-end-color: var-get($theme, 'cell-active-border-color');
            border-bottom-color: var-get($theme, 'cell-active-border-color');
        }
    }

    %grid-cell--invalid {
        padding-inline-end: rem(4px) !important;

        > igx-icon {
            margin-inline-start: auto;
            color: color($color: 'error');
            width: var(--igx-icon-size, rem(18px));
            height: var(--igx-icon-size, rem(18px));
            font-size: var(--igx-icon-size, rem(18px));
        }

        %grid-cell-text {
            width: 100%;
        }

        .igx-input-group__bundle {
            &:focus-within {
                &::after {
                    border: none !important;
                }
            }
        }
    }

    %grid-cell--valid {
        box-shadow: inset 0 0 0 rem(2px) color($color: 'success') !important;
    }

    %grid-cell--pinned-selected,
    %grid-cell--selected {
        color: var-get($theme, 'cell-selected-text-color');
        background: var-get($theme, 'cell-selected-background');
        // this is causing an issue https://github.com/IgniteUI/igniteui-angular/issues/4981
        // border-bottom: 0;

        %igx-grid__tree-grouping-indicator {
            &:hover {
                color: var-get($theme, 'cell-selected-text-color');
            }
        }
    }

    %grid-row--selected {
        color: var-get($theme, 'row-selected-text-color');
        background: var-get($theme, 'row-selected-background');

        %grid-cell--selected,
        %grid-cell--pinned-selected {
            color: var-get($theme, 'cell-selected-within-text-color');
            background: var-get($theme, 'cell-selected-within-background');
        }

        &:hover {
            background: var-get($theme, 'row-selected-hover-background');
            color: var-get($theme, 'row-selected-hover-text-color');

            %grid-cell--column-selected {
                color: var-get($theme, 'row-selected-hover-text-color');
                background: var-get($theme, 'row-selected-hover-background');
            }
        }

        %igx-grid__tree-grouping-indicator {
            color: var-get($theme, 'row-selected-text-color');

            &:hover {
                color: var-get($theme, 'row-selected-text-color');
            }
        }
    }

    %grid-cell--column-selected {
        color: var-get($theme, 'row-selected-text-color');
        background: var-get($theme, 'row-selected-background');
    }

    %grid-cell--cross-selected {
        color: var-get($theme, 'cell-selected-within-text-color');
        background: var-get($theme, 'cell-selected-within-background');
    }

    %igx-grid__td--new {
        color: var-get($theme, 'cell-new-color');
    }

    %igx-grid__td--edited {
        %grid-cell-text {
            font-style: italic;
            color: var-get($theme, 'cell-edited-value-color');
            padding: 0 rem(1px);
        }
    }

    %igx-grid__tr--deleted {
        %grid-cell-text {
            font-style: italic;
            color: color(map.get($theme, 'palette'), 'error');
            text-decoration: line-through;
        }
    }

    %igx-grid__tr--disabled {
        %grid-cell-text {
            color: var-get($theme, 'cell-disabled-color');
        }
    }

    %igx-grid__td--editing {
        $editing-outline-width: rem(2px);
        background: var-get($theme, 'cell-editing-background') !important;
        box-shadow: inset 0 0 0 $editing-outline-width var-get($theme, 'edit-mode-color');
        padding: 0 !important;

        // Have a more stable visual editing experience
        > igx-input-group,
        igx-combo,
        igx-select,
        igx-date-picker,
        igx-time-picker {
            position: relative;
            height: calc(100% - #{$editing-outline-width * 2});
            width: calc(100% - #{$editing-outline-width * 2});
            inset-inline-start: $editing-outline-width;
            overflow: hidden;
        }

        igx-input-group {
            height: 100%;

            input {
                height: 100%;
                color: var-get($theme, 'cell-editing-foreground');
            }

            input:focus {
                color: var-get($theme, 'cell-editing-focus-foreground');
            }

            --theme: fluent;
        }

        .igx-input-group__bundle {
            height: 100% !important;
            min-height: 100% !important;
            border: none !important;

            &::before {
                content: none !important;
            }

            &::after {
                display: none;
            }
        }

        .igx-input-group__bundle-main,
        .igx-input-group__bundle-start,
        .igx-input-group__bundle-end {
            height: calc(100% - #{$editing-outline-width * 2});
        }

        &.igx-grid__td--invalid {
            box-shadow: inset 0 0 0 rem(2px) color($color: 'error') !important;
            padding-inline-end: rem(4px) !important;
        }

        igx-prefix,
        igx-suffix {
            border-radius: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        igx-date-range-picker {
            height: 100%;
        }

        igx-time-picker [igxLabel] {
            display: none;
        }

        input {
            margin: 0 auto;
            max-width: 100%;
        }

        %form-group-input {
            // ignore global typography
            font-size: $grid-cell-fs !important;
            line-height: $grid-cell-lh !important;
        }

        &%grid-cell-number {
            justify-content: flex-start !important;
        }
    }

    %grid-cell--pinned {
        position: relative;
        background: inherit;
        z-index: 9999;
    }

    %grid-cell--pinned--column-selected {
        color: var-get($theme, 'row-selected-text-color');
        background: var-get($theme, 'row-selected-background');

        &:hover {
            background: var-get($theme, 'row-selected-hover-background');
            color: var-get($theme, 'row-selected-text-color');
        }
    }

    %grid-cell--pinned-last {
        border-inline-end: map.get($cell-pin, 'style') map.get($cell-pin, 'color') !important;

        %igx-grid__filtering-cell,
        %grid-cell-header {
            border-inline-end: none;
        }

        &%grid-cell--editing {
            border-inline-end: map.get($cell-pin, 'style') var-get($theme, 'cell-selected-background') !important;
        }
    }

    %grid-cell--pinned-first {
        border-inline-start: map.get($cell-pin, 'style') map.get($cell-pin, 'color') !important;

        &%grid-cell--editing {
            border-inline-start: map.get($cell-pin, 'style') var-get($theme, 'cell-selected-background') !important;
        }
    }

    %grid-cell--row-pinned-first {
        overflow: hidden;
    }

    %grid-cell--pinned-chip {
        margin-inline-end: pad-inline(rem(4px), rem(8px), rem(12px));
    }

    %grid-cell-header {
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        font-size: $grid-head-fs;
        font-weight: $grid-head-fw;
        min-width: 0;
        padding-inline: pad-inline(
            map.get($grid-header-padding-inline, 'compact'),
            map.get($grid-header-padding-inline, 'cosy'),
            map.get($grid-header-padding-inline, 'comfortable')
        );
        padding-block: 0;
        border-inline-end: $grid-header-border;
        min-height: var(--header-size);
        outline-style: none;
        overflow: hidden;
        transition: color 250ms ease-in-out;
    }

    %grid-cell-header--filtering {
        background: var-get($theme, 'filtering-header-background');
        color: var-get($theme, 'filtering-header-text-color');
        z-index: 3;
    }

    %grid-cell-header-title {
        @include ellipsis();
        font-weight: $grid-header-weight;
        min-width: 3ch;
        user-select: none;
        cursor: initial;
        flex-grow: 1; /* hey IE, the text should take most of the space */
        // align-self: flex-end; /* commenting out for now on external request */
        line-height: var(--header-size);
        transition: color 250ms ease-in-out;
    }

    %grid-cell-header-icons {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        user-select: none;
        min-width: rem(30px); /* sort-icon + filter icon width */
        height: var(--header-size);
        align-self: flex-end;

        &:empty {
            min-width: 0;
        }

        .sort-icon {
            position: relative;
            display: flex;

            igx-icon {
                width: var(--igx-icon-size, #{rem(15px)});
                height: var(--igx-icon-size, #{rem(15px)});
                font-size: var(--igx-icon-size, #{rem(15px)});
            }

            &::after {
                content: attr(data-sortIndex);
                position: absolute;
                top: rem(-5px);
                inset-inline-end: rem(-1px);
                font-size: rem(10px);
                text-align: end;
                font-family: sans-serif;
                line-height: rem(10px);
            }
        }
    }


    %igx-grid-th__expander {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-end: rem(8px);
        cursor: pointer;

        igx-icon {
            color: var-get($theme, 'expand-icon-color');
        }

        &:hover {
            igx-icon {
                color: var-get($theme, 'expand-icon-hover-color');
            }
        }
    }

    %igx-grid-th__group-title {
        @include ellipsis();
    }

    %igx-grid-th--collapsible {
        justify-content: normal;
    }

    %igx-grid-th--selectable {
        color: var-get($theme, 'header-selected-text-color');
        background: var-get($theme, 'header-selected-background');

        @if $variant != 'indigo-design' {
            opacity: .7;

            &%grid__drag-ghost-image {
                opacity: 1;
            }
        } @else {
            opacity: 1;
        }

        .sort-icon {
            color: var-get($theme, 'header-selected-text-color');

            ::after {
                background: var-get($theme, 'header-selected-background');
            }
        }

        &%igx-grid-th--sorted {
            .sort-icon {
                color: var-get($theme, 'header-selected-text-color');

                &:focus,
                &:hover {
                    color: var-get($theme, 'header-selected-text-color');
                }
            }
        }
    }

    %igx-grid-th--selected {
        color: var-get($theme, 'header-selected-text-color');
        background: var-get($theme, 'header-selected-background');

        .sort-icon::after {
            background: var-get($theme, 'header-selected-background');
        }

        &%igx-grid-th--sorted {
            .sort-icon {
                color: var-get($theme, 'header-selected-text-color');

                &:focus,
                &:hover {
                    color: var-get($theme, 'header-selected-text-color');
                }
            }
        }
    }

    %igx-grid-th--active {
        @extend %grid-cell--active;

        %igx-grid-th--selected,
        %igx-grid-th--selectable {
            @extend %grid-cell--active;
        }
    }

    %igx-grid-summary--active {
        @extend %grid-cell--active !optional;
    }

    %igx-grid-th--sortable {
        .sort-icon {
            cursor: pointer;
            opacity: .7;

            &:hover {
                opacity: 1;
            }
        }
    }

    %igx-grid-th--sorted {
        .sort-icon {
            opacity: 1;
            color: var-get($theme, 'sorted-header-icon-color');

            &:hover {
                color: var-get($theme, 'sortable-header-icon-hover-color');
            }
        }
    }

    %igx-grid-th--filtrable {
        %grid-cell-header-title {
            opacity: .7;
        }
    }

    %igx-grid-th--filtrable-sortable {
        .sort-icon {
            cursor: pointer;
            opacity: .7;

            &:hover {
                opacity: 1;
            }
        }
    }

    .sort-icon {
        transition: all 250ms ease-in-out;
    }

    %grid-cell-number {
        text-align: $grid-cell-align-num;
        justify-content: flex-end;

        %grid-cell-header-icons {
            justify-content: flex-start;
            order: -1;

            .sort-icon {
                order: 1;
            }
        }
    }

    %grid__cbx-selection {
        display: flex;
        justify-content: center;
        align-items: center;
        background: inherit;
        z-index: 4;
        background-clip: border-box;
    }

    %cbx-padding {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: pad-inline($cbx-padding-compact, $cbx-padding-cosy, $cbx-padding);
    }

    %grid__resize-handle {
        position: absolute;
        width: rem(4px);
        top: 0;
        inset-inline-end: rem(-2px);
        height: 100%;
        z-index: 2;
    }

    %grid__resize-line {
        position: absolute;
        cursor: col-resize;
        width: rem(4px);
        background: var-get($theme, 'resize-line-color');
        z-index: 2;

        &::before,
        &::after {
            position: absolute;
            content: '';
            height: 100%;
            width: rem(96px);
        }

        &::before {
            inset-inline-end: 100%;
        }

        &::after {
            inset-inline-start: 100%;
        }
    }

    %grid-summaries {
        display: flex;
        overflow: hidden;
        background: var-get($theme, 'root-summaries-background');
        outline-style: none;

        %igx-grid-summary__result {
            color: var-get($theme, 'root-summaries-text-color');
        }
    }

    %grid-summaries--body {
        background: var-get($theme, 'body-summaries-background');
        border-bottom: rem(1px) dashed var-get($theme, 'row-border-color');

        &:last-of-type {
            border-bottom: none;
        }

        %igx-grid-summary__result {
            color: var-get($theme, 'body-summaries-text-color');
        }
    }

    %grid-summaries-patch {
        @if $bootstrap-theme {
            background: color($color: 'gray', $variant: 100);
        } @else {
            background: inherit;
        }
        position: relative;
        z-index: 1;
        border-inline-end: rem(1px) solid var-get($theme, 'header-border-color');
    }

    // Column moving
    %igx-grid-th__drop-indicator-left,
    %igx-grid-th__drop-indicator-right {
        position: absolute;
        width: rem(1px);
        height: 100%;
        top: 0;
        z-index: 1;
    }

    %igx-grid-th__drop-indicator-left {
        inset-inline-start: rem(-1px);
    }

    %igx-grid-th__drop-indicator-right {
        inset-inline-end: rem(-1px);
    }

    %igx-grid-th__drop-indicator--active {
        &%igx-grid-th__drop-indicator-left,
        &%igx-grid-th__drop-indicator-right {
            border-inline-end: rem(1px) solid var-get($theme, 'drop-indicator-color');
        }

        &::after,
        &::before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            inset-inline-start: rem(-3px);
        }

        &::before {
            bottom: 0;
            border-width: 0 rem(4px) rem(4px);
            border-color: transparent transparent var-get($theme, 'drop-indicator-color');
        }

        &::after {
            top: 0;
            border-width: rem(4px) rem(4px) 0;
            border-color: var-get($theme, 'drop-indicator-color') transparent transparent;
        }
    }

    %grid__scroll-on-drag-left,
    %grid__scroll-on-drag-right {
        position: absolute;
        width: rem(15px);
        top: 0;
        height: 100%;
        z-index: 25;
    }

    %grid__scroll-on-drag-left {
        inset-inline-start: 0;
    }

    %grid__scroll-on-drag-right {
        inset-inline-end: 0;
    }

    %grid__scroll-on-drag-pinned {
        position: absolute;
        width: rem(15px);
        height: 100%;
        top: 0;
        z-index: 25;
    }

    %grid__drag-ghost-image {
        position: absolute;
        display: flex;
        align-items: center;
        background: var-get($theme, 'ghost-header-background');
        color: var-get($theme, 'ghost-header-text-color');
        min-width: rem(168px);
        max-width: rem(320px);
        height: var(--header-size);
        min-height: var(--header-size);
        top: rem(-99999px);
        inset-inline-start: rem(-99999px);
        border: none;
        box-shadow: var-get($theme, 'drag-shadow');
        overflow: hidden;
        z-index: 20;

        %grid-cell-header-title {
            @include ellipsis();
            flex: 1 0 0;
            text-align: end;
        }

        %grid-cell-header-icons {
            display: none;
        }

        %grid-thead-title {
            border: none;
        }
    }

    %grid__drag-ghost-image-icon {
        color: var-get($theme, 'ghost-header-icon-color');
        margin-inline-end: rem(12px);
    }

    %grid__drag-ghost-image-icon-group {
        color: var-get($theme, 'ghost-header-icon-color');
        padding: $padding-comfortable;
        padding-inline-end: 0;
        margin-inline-end: rem(8);
    }

    %igx-grid__drag-col-header {
        background: var-get($theme, 'header-background');

        %grid-cell-header {
            > * {
                opacity: .4;
            }
        }
    }

    // Group by section
    %igx-grid__group-row {
        background: var-get($theme, 'group-row-background');
        display: flex;
        outline-style: none;
        border-bottom: rem(1px) solid var-get($theme, 'row-border-color');
        min-height: var(--header-size);

        %igx-grid__drag-indicator {
            cursor: default;
            flex-grow: 0;
        }

        %grid__cbx-selection {
            background: initial;
        }
    }

    %igx-grid__group-row--active {
        background: var-get($theme, 'group-row-selected-background');
        @extend %grid-cell--active !optional;

        %igx-grid__grouping-indicator {
            color: var-get($theme, 'expand-icon-color');
        }

        %igx-grid__drag-indicator {
            border: rem(1px) solid var-get($theme, 'cell-active-border-color');
            border-inline-start-width: 0;
            border-inline-end-width: 0;
            box-shadow: inset rem(1px) 0 0 0 var-get($theme, 'cell-active-border-color');
        }

        &:hover {
            background: var-get($theme, 'group-row-selected-background');
        }
    }

    %igx-group-label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: rem(16px);

        > * {
            margin-inline-end: rem(4px);

            &:last-child {
                margin-inline-end: 0;
            }
        }
    }

    %igx-group-label__icon {
        user-select: none;

        igx-icon {
            color: var-get($theme, 'group-label-icon');
            width: var(--igx-icon-size, #{rem(16px)});
            height: var(--igx-icon-size, #{rem(16px)});
            font-size: var(--igx-icon-size, #{rem(16px)});
        }
    }

    %igx-group-label__column-name {
        color: var-get($theme, 'group-label-column-name-text');
        font-weight: 600;
        font-size: rem(12px);
    }

    %igx-group-label__count-badge {
        --background-color: #{var-get($theme, 'group-count-background')};
        --text-color: #{var-get($theme, 'group-count-text-color')};

        > span {
            font-size: $grid-head-fs;
        }
    }

    %igx-group-label__text {
        font-size: rem(13px);
        color: var-get($theme, 'group-label-text')
    }

    [dir='rtl'] {
        %igx-grid__group-content {
            padding-inline-start: pad-inline(
                map.get($grid-grouping-indicator-padding, 'compact'),
                map.get($grid-grouping-indicator-padding, 'cosy'),
                map.get($grid-grouping-indicator-padding, 'comfortable')
            );
        }

        %igx-group-label {
            > * {
                margin-inline-start: rem(4px);

                &:last-child {
                    margin-inline-start: 0;
                }
            }
        }
    }

    %igx-grid__group-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1 1 auto;
        padding-inline-start: pad-inline(
            map.get($grid-grouping-indicator-padding, 'compact'),
            map.get($grid-grouping-indicator-padding, 'cosy'),
            map.get($grid-grouping-indicator-padding, 'comfortable')
        );
        min-height: sizable(
            map.get($grid-header-height, 'compact'),
            map.get($grid-header-height, 'cosy'),
            map.get($grid-header-height, 'comfortable')
        );

        &:focus {
            outline: transparent;
        }
    }

    %igx-grid__row-indentation {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline-start: pad-inline(
            map.get($grid-grouping-indicator-padding, 'compact'),
            map.get($grid-grouping-indicator-padding, 'cosy'),
            map.get($grid-grouping-indicator-padding, 'comfortable')
        );
        padding-inline-end: pad-inline(
            map.get($grid-grouping-indicator-padding, 'compact'),
            map.get($grid-grouping-indicator-padding, 'cosy'),
            map.get($grid-grouping-indicator-padding, 'comfortable')
        );
        border-inline-end: rem(1px) solid var-get($theme, 'header-border-color');
        background: inherit;
        z-index: 1;
        background-clip: border-box;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: rem(1px);
            bottom: rem(-1px);
            inset-inline-start: 0;
            background: transparent;
        }

        %igx-icon-button-display {
            width: rem(28px);
            height: rem(28px);
            color: var-get($theme, 'expand-all-icon-color');
        }

        &:focus,
        &:hover {
            %igx-icon-button-display {
                color: var-get($theme, 'expand-all-icon-hover-color');
            }
        }
    }

    %igx-grid-grouparea {
        grid-row: 2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        border-bottom: $grid-header-border;
        background: var-get($theme, 'grouparea-background');
        color: var-get($theme, 'grouparea-color');
        min-height: var(--grouparea-size);
        padding-inline: pad-inline(
            map.get($grouparea-padding-inline, 'compact'),
            map.get($grouparea-padding-inline, 'cosy'),
            map.get($grouparea-padding-inline, 'comfortable')
        );
        padding-block: 0;
        z-index: 2;
        height: 100%;
        overflow: hidden;

        &:focus {
            outline-style: none;
        }

        %igx-chip {
            margin-block: pad-block(rem(4px), rem(8px), rem(8px));
        }
    }

    %igx-grid-grouparea__connector {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 rem(4px);

        igx-icon {
            width: var(--igx-icon-size, #{rem(16px)});
            height: var(--igx-icon-size, #{rem(16px)});
            font-size: var(--igx-icon-size, #{rem(16px)});
        }

        [dir='rtl'] & {
            transform: scaleX(-1);
        }
    }

    %igx-drop-area {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-width: rem(80px);
        height: sizable(
            map.get($drop-area-height, 'compact'),
            map.get($drop-area-height, 'cosy'),
            map.get($drop-area-height, 'comfortable')
        );
        padding-inline: pad-inline(
            map.get($grid-cell-padding-inline, 'compact'),
            map.get($grid-cell-padding-inline, 'cosy'),
            map.get($grid-cell-padding-inline, 'comfortable')
        );
        padding-block: 0;
        flex: 1 0 0%;
        background: var-get($theme, 'drop-area-background');
        border-radius: var-get($theme, 'drop-area-border-radius');

        %igx-drop-area__icon {
            color: var-get($theme, 'drop-area-icon-color');
            width: rem(16px);
            height: rem(16px);
            font-size: rem(16px);
            margin-inline-end: rem(8px);
        }
    }

    %igx-drop-area--hover {
        background: var-get($theme, 'drop-area-on-drop-background');
    }

    %igx-drop-area__text {
        @include ellipsis();
        color: var-get($theme, 'drop-area-text-color');
        font-size: rem(13px);
    }

    %igx-grid__grouping-indicator {
        position: relative;
        display: flex;
        user-select: none;
        justify-content: center;
        align-items: center;
        z-index: 1;
        cursor: pointer;
        padding-inline-end: $grouping-padding-right;
        margin-inline-start: sizable(
            #{map.get($grid-grouping-indicator-padding, 'compact')},
            #{map.get($grid-grouping-indicator-padding, 'cosy')},
            #{map.get($grid-grouping-indicator-padding, 'comfortable')
        });
        min-height: var(--header-size);

        igx-icon {
            color: var-get($theme, 'expand-icon-color');
            width: var(--igx-icon-size, #{$indicator-icon-width});
            height: var(--igx-icon-size, #{$indicator-icon-width});
            font-size: var(--igx-icon-size, #{$indicator-icon-width});
        }

        &:hover,
        &:focus {
            outline-style: none;

            igx-icon {
                color: var-get($theme, 'expand-icon-hover-color');
            }
        }

        [dir='rtl'] & {
            transform: scaleX(-1);
        }
    }

    %igx-grid__header-indentation {
        position: relative;
        padding-inline-end: sizable(
            map.get($grid-grouping-indicator-padding, 'compact'),
            map.get($grid-grouping-indicator-padding, 'cosy'),
            map.get($grid-grouping-indicator-padding, 'comfortable')
        );
        border-inline-end: rem(1px) solid var-get($theme, 'header-border-color');
        background: var-get($theme, 'header-background');
        z-index: 4;

        igx-icon {
            --component-size: var(--grid-size);
        }
    }


    %igx-grid__group-expand-btn {
        position: absolute;
        cursor: pointer;
        user-select: none;
        inset-block-start: calc(50% - 12px);
        inset-inline-start: sizable(
            map.get($grid-grouping-indicator-padding, 'compact'),
            map.get($grid-grouping-indicator-padding, 'cosy'),
            map.get($grid-grouping-indicator-padding, 'comfortable')
        );

        &:hover {
            color: var-get($theme, 'expand-icon-hover-color');
        }

        &%igx-grid__group-expand-btn--push {
            inset-block-start: sizable(
                math.div(map.get($grid-header-height, 'compact') - rem(24px), 2),
                math.div(map.get($grid-header-height, 'cosy') - rem(24px), 2),
                math.div(map.get($grid-header-height, 'comfortable') - rem(24px), 2)
            );
        }
    }

    @for $i from 1 through 10 {
        $row-indentation-level: (
            comfortable: calc(#{$i * map.get($grid-grouping-indicator-padding, 'comfortable')} + #{$indicator-icon-width}),
            cosy: calc(#{$i * map.get($grid-grouping-indicator-padding, 'cosy')} + #{$indicator-icon-width}),
            compact: calc(#{$i * map.get($grid-grouping-indicator-padding, 'compact')} + #{$indicator-icon-width})
        );

        $level--comfortable: map-get($row-indentation-level, 'comfortable');
        $level--cosy: map-get($row-indentation-level, 'cosy');
        $level--compact: map-get($row-indentation-level, 'compact');

        %igx-grid__row-indentation--level-#{$i} {
            padding-inline-start: pad-inline($level--compact, $level--cosy, $level--comfortable);
        }

        $indicator-padding--comfortable: #{$i * map.get($grid-grouping-indicator-padding, 'comfortable')};
        $indicator-padding--cosy: #{$i * map.get($grid-grouping-indicator-padding, 'cosy')};
        $indicator-padding--compact: #{$i * map.get($grid-grouping-indicator-padding, 'compact')};

        %igx-grid__group-row--padding-level-#{$i} {
            %igx-grid__grouping-indicator {
                padding-inline-start: pad-inline($indicator-padding--compact, $indicator-padding--cosy, $indicator-padding--comfortable);
            }
        }
    }

    %igx-grid__outlet {
        --ig-size: var(--grid-size);

        z-index: 10002;
        position: fixed;
    }

    %igx-grid__loading-outlet {
        z-index: 10003;

        > %overlay-wrapper--modal {
            background: none;
        }

        %circular-display {
            width: rem(50);
            height: rem(50);
        }
    }

    %igx-grid__row-editing-outlet {
        z-index: 10000;
        position: absolute;

        %overlay-wrapper {
            /* Change wrapper position from 'fixed' to 'absolute' so that it is hidden when scrolled below the parent grid body content. */
            position: absolute;
        }
    }

    %igx-grid__addrow-snackbar {
        position: absolute;
        z-index: 5;
        bottom: rem(24px);
        inset-inline-start: 50%;
        transform: translateX(-50%);
    }

    %igx-grid__filtering-cell {
        display: flex;
        align-items: center;
        border-inline-end: $grid-header-border;
        border-top: $grid-header-border;
        height: var(--header-size);
        padding-inline: pad-inline(
            map.get($grid-header-padding-inline, 'compact'),
            map.get($grid-header-padding-inline, 'cosy'),
            map.get($grid-header-padding-inline, 'comfortable')
        );
        padding-block: 0;
        overflow: hidden;

        igx-chips-area {
            transition: transform .25s $ease-out-back;
            flex-wrap: nowrap;

            .igx-filtering-chips__connector {
                font-size: rem(12px);
                text-transform: uppercase;
                font-weight: 600;
                margin: 0 rem(8px);
            }
        }
    }

    %igx-grid__filtering-cell--selected {
        color: var-get($theme, 'header-selected-text-color');
        background: var-get($theme, 'header-selected-background');
    }

    %igx-grid__filtering-cell-indicator {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline-end: rem(8px);
        margin-inline-start: rem(8px);
        cursor: pointer;
        visibility: visible;

        igx-icon {
            width: var(--igx-icon-size, #{rem(18px)});
            height: var(--igx-icon-size, #{rem(18px)});
            font-size: var(--igx-icon-size, #{rem(18px)});
        }

        %igx-badge-display {
            --size: #{rem(14px)};
            --font-size: #{rem(12px)};
            line-height: 0;
            position: absolute;
            inset-inline-end: 0;
        }
    }

    %igx-grid__filtering-cell-indicator--hidden {
        visibility: hidden;
    }

    %igx-grid__filtering-row {
        $filtering-row-height: #{sizable(
            map.get($grid-header-height, 'compact'),
            map.get($grid-header-height, 'cosy'),
            map.get($grid-header-height, 'comfortable')
        )};
        position: absolute;
        display: flex;
        width: 100%;
        height: $filtering-row-height;
        padding-inline: pad-inline($cell-padding-compact, $cell-padding-cosy, $cell-padding-comfortable);
        align-items: center;
        justify-content: space-between;
        background: var-get($theme, 'filtering-row-background');
        color: var-get($theme, 'filtering-row-text-color');
        inset-inline-start: 0;
        bottom: 0;
        z-index: 3;

        &::after {
            display: block;
            position: absolute;
            content: '';
            background: inherit;
            inset-inline-start: 0;
            inset-inline-end: 0;
            top: 0;
            bottom: 0;
            box-shadow: 0 rem(1px) 0 var-get($theme, 'filtering-row-background'),
                0 rem(4px) rem(10px) rgba(0, 0, 0, .12);
            z-index: -1;
        }

        igx-input-group {
            --theme: fluent;
            width: 100%;
            max-width: rem(200px);
            min-width: rem(140px);

            --size: calc(#{$filtering-row-height} - #{rem(8px)});
        }

        igx-prefix:focus {
            color: color(map.get($theme, 'palette'), 'secondary');
        }

        igx-suffix  {
            igx-icon {
                outline-style: none;

                &:focus {
                    color: color($color: 'secondary');
                }

                + igx-icon {
                    margin-inline-start: rem(4px);
                }
            }
        }

        [igxIconButton] {
            --ig-size: 1;
        }

        @if ($variant == 'bootstrap') {
            [igxButton] {
                margin: rem(4px);
            }
        }
    }

    %igx-grid__filtering-dropdown-items {
        display: flex;
        align-items: center;
    }

    %igx-grid__filtering-dropdown-text {
        margin-inline-start: rem(16px);
    }

    %igx-grid__filtering-row-main {
        display: flex;
        flex: 1;
        overflow: hidden;
        max-width: calc(100% - 176px);
        min-width: rem(56px);

        igx-chips-area {
            transition: transform .25s $ease-out-back;
            flex-wrap: nowrap;
            margin: 0 rem(8px);
        }

        igx-chip {
            margin: 0 rem(4px);
        }

        [igxButton] {
            igx-icon {
                position: absolute;
                inset-inline-start: rem(12px);
                /* IE fix for vertical alignment*/
                top: 50%;
                transform: translateY(-50%);
            }

            span {
                margin-inline-start: rem(16px);
            }
        }
    }

    %igx-grid__filtering-scroll-start {
        width: rem(24px);
        height: rem(24px);
        position: relative;
        overflow: visible;
        margin: 0 rem(8px);
        z-index: 1;

        &::after {
            @include _filtering-scroll-mask($theme, right);
            inset-inline-start: calc(100% + 6px);
        }

        [dir='rtl'] & {
            transform: scaleX(-1);

            &::after {
                content: initial;
            }

            &::before {
                @include _filtering-scroll-mask($theme, right);
                inset-inline-end: calc(100% + 6px);
            }
        }
    }

    %igx-grid__filtering-scroll-end {
        width: rem(24px);
        height: rem(24px);
        position: relative;
        overflow: visible;
        margin: 0 rem(8px);
        z-index: 1;

        &::before {
            @include _filtering-scroll-mask($theme, left);
            inset-inline-end: calc(100% + 6px);
        }

        [dir='rtl'] & {
            transform: scaleX(-1);

            &::before {
                content: initial;
            }

            &::after {
                @include _filtering-scroll-mask($theme, left);
                inset-inline-start: calc(100% + 6px);
            }
        }
    }

    %igx-grid__tr--highlighted {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            width: rem(4px);
            height: 100%;
            background: var-get($theme, 'row-highlight');
            z-index: 3;
        }

        %igx-grid__tr--edited {
            &::before {
                inset-inline-start: rem(4px);
            }
        }

        &::before {
            inset-inline-start: rem(4px);
        }
    }

    %igx-grid__tr-container {
        overflow: auto;
        width: 100%;
        border-bottom: rem(1px) solid var-get($theme, 'row-border-color');
    }

    %igx-grid__tr-container--active {
        @extend %grid-cell--active !optional;
    }

    %igx-grid__hierarchical-expander {
        user-select: none;
        background: inherit;
        padding-inline: pad-inline(
            map.get($hierarchical-grid-indent, 'compact'),
            map.get($hierarchical-grid-indent, 'cosy'),
            map.get($hierarchical-grid-indent, 'comfortable')
        );
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 3;
        color: var-get($theme, 'expand-icon-color');
        background-clip: border-box;

        &:focus {
            outline: none;

            igx-icon {
                color: var-get($theme, 'expand-icon-hover-color');
            }
        }

        &:hover {
            igx-icon {
                color: var-get($theme, 'expand-icon-hover-color');
            }
        }

        igx-icon {
            --component-size: 3;

            color: var-get($theme, 'expand-icon-color');
            max-width: $hierarchical-action-icon;
            min-width: $hierarchical-action-icon;
        }

        [dir='rtl'] & {
            transform: scaleX(-1);
        }

        &--empty {
            cursor: default;
            pointer-events: none;
        }
    }

    %igx-grid__hierarchical-expander--header {
        background: inherit;
        border-inline-end: rem(1px) solid var-get($theme, 'header-border-color');
        z-index: 3;
        background-clip: border-box;

        igx-icon {
            display: flex;
            align-items: center;
        }
    }

    %igx-grid__hierarchical-expander--push {
        align-items: flex-start;

        igx-icon {
            min-height: var(--header-size);
            max-height: var(--header-size);
        }
    }

    %igx-grid__header-indentation--no-border {
        border-inline-end: rem(1px) solid transparent;
    }

    %igx-grid__hierarchical-indent {
        display: flex;
        margin-inline-start: pad-inline(
            map.get($hierarchical-indent, 'compact'),
            map.get($hierarchical-indent, 'cosy'),
            map.get($hierarchical-indent, 'comfortable')
        );
        margin-inline-end: pad-inline(
            map.get($hierarchical-grid-indent, 'compact'),
            map.get($hierarchical-grid-indent, 'cosy'),
            map.get($hierarchical-grid-indent, 'comfortable')
        );
        margin-block: pad-block(
            map.get($hierarchical-grid-indent, 'compact'),
            map.get($hierarchical-grid-indent, 'cosy'),
            map.get($hierarchical-grid-indent, 'comfortable')
        );

        &--scroll {
            margin-inline-end: pad-inline(
                map.get($hierarchical-indent-scroll, 'compact'),
                map.get($hierarchical-indent-scroll, 'cosy'),
                map.get($hierarchical-indent-scroll, 'comfortable')
            );
        }
    }

    @include excel-filtering($theme);

    %advanced-filtering-dialog {
        background: var-get($theme, 'filtering-row-background');
        box-shadow: elevation(12);

        igx-query-builder {
            --igx-query-builder-background: #{var-get($theme, 'filtering-row-background')};
            --igx-query-builder-background-and: #{var-get($theme, 'filtering-background-and')};
            --igx-query-builder-background-and--focus: #{var-get($theme, 'filtering-background-and--focus')};
            --igx-query-builder-background-or: #{var-get($theme, 'filtering-background-or')};
            --igx-query-builder-background-or--focus: #{var-get($theme, 'filtering-background-or--focus')};
            box-shadow: none;
        }

        igx-query-builder-header {
            cursor: grab;
        }
    }

    %igx-grid__filtering-row-editing-buttons--small,
    %igx-grid__filtering-row-editing-buttons {
        display: flex;
        align-items: center;

        button {
            transition: none;
        }
    }

    %igx-grid__filtering-row-editing-buttons--small {
        button {
            &:not([disabled]) {
                igx-icon {
                    color: var-get($theme, 'sorted-header-icon-color');
                }
            }
        }
    }

    %igx-grid__tr-action {
        &:last-of-type {
            border-inline-end: var-get($theme, 'header-border-width') var-get($theme, 'header-border-style') var-get($theme, 'header-border-color');
        }
    }

    // Pivot grid
    %igx-grid__pivot--super-compact {
        --ig-size: 1 !important;
        %grid-cell-display,
        %grid-cell-header {
            padding: 0 rem(4px) !important;
            min-height: rem(24px) !important;
            height: rem(24px);
        }

        %grid-cell-header {
            > * {
                line-height: normal;
                align-self: initial;
                max-height: 100%;
            }
        }
    }


    %grid-thead--pivot {
        display: flex;

        %grid-thead--virtualizationWrapper {
            border-inline-start: var-get($theme, 'header-border-width') var-get($theme, 'header-border-style') var-get($theme, 'header-border-color');
        }
    }

    %grid-thead--virtualizationWrapper {
        height: 100%;
    }

    %grid-thead--virtualizationContainer {
        overflow: visible;
        height: 100%;
    }

    %igx-grid__tr-pivot {
        display: flex;
        align-items: center;
        background: inherit;
        overflow: hidden;
        z-index: 3;
        height: var(--header-size);
        padding-inline: pad-inline(
            map.get($grid-header-padding-inline, 'compact'),
            map.get($grid-header-padding-inline, 'cosy'),
            map.get($grid-header-padding-inline, 'comfortable')
        );
        padding-block: 0;
        background-clip: border-box !important;
        border-inline-start: var-get($theme, 'header-border-width') var-get($theme, 'header-border-style') var-get($theme, 'header-border-color');
        border-bottom: var-get($theme, 'header-border-width') var-get($theme, 'header-border-style') var-get($theme, 'header-border-color');

        igx-chips-area {
            flex-wrap: nowrap;
            width: auto;

            > * {
                margin-inline-end: rem(4px);
            }

            &:last-child {
                margin-inline-end: 0;
            }
        }
    }

    %igx-grid__pivot-filter-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        > igx-badge {
            position: absolute;
            top: rem(-4px);
            inset-inline-start: 60%;
            width: rem(18px);
            min-width: rem(18px);
            height: rem(18px);
            font-size: rem(10px);
            pointer-events: none;
            user-select: none;
        }
    }

    %igx-grid__pivot-empty-chip-area {
        line-height: normal;
        font-size: rem(14px);
        margin-inline-end: 0 !important;
    }

    %igx-grid__tr-pivot--row-area {
        height: auto !important;
        align-items: flex-end;
        padding-bottom: pad-block(
            map.get($pivot-row-aria-padding, 'compact'),
            map.get($pivot-row-aria-padding, 'cosy'),
            map.get($pivot-row-aria-padding, 'comfortable')
        );
        border-inline-start: 0;
        border-bottom: 0;
    }

    %igx-grid__tr-pivot--small-row-area {
        height: var(--header-size);
        align-items: flex-end;

        border-inline-start: 0;
        border-inline-end: 0;
        border-bottom: var-get($theme, 'header-border-width') var-get($theme, 'header-border-style') var-get($theme, 'header-border-color');
    }

    %igx-grid__tr-pivot--filter-container {
        display: flex;
        flex-direction: column;
    }

    %igx-grid__tr-pivot--chip_drop_indicator {
        width: rem(2px);
        background: var-get($theme, 'resize-line-color');
        visibility: hidden;
    }

    %igx-grid__tr-pivot--drop-row-area {
        flex-grow: 1;
    }

    %igx-grid__tr-pivot--filter {
        height: var(--header-size);

        border-inline-start: 0;
        border-inline-end: 0;
        border-bottom: var-get($theme, 'header-border-width') var-get($theme, 'header-border-style') var-get($theme, 'header-border-color');
    }

    %igx-grid-thead__wrapper--pivot {
        border-bottom: 0;
    }

    %igx-grid__tr-pivot-group {
        flex: 1;
    }

    %igx-grid__tr-pivot-toggle-icons {
        display: inline-flex !important;
    }

    %igx-grid__tr-pivot--columnDimensionLeaf {
        box-shadow: none;

        igx-grid-header {
            border: none;
        }
    }

    %igx-grid__tr-pivot--columnMultiRowSpan {
        igx-grid-header {
            > * {
                visibility: hidden;
            }
        }
    }

    %igx-grid__tr-header-row {
        igx-pivot-row-dimension-header-group {
            igx-pivot-row-dimension-header {
                align-items: center;
            }
        }

        igx-pivot-row-header-group {
            padding-left: pad-inline(
                map.get($grid-header-padding-inline, 'compact'),
                map.get($grid-header-padding-inline, 'cosy'),
                map.get($grid-header-padding-inline, 'comfortable')
            );

            igx-pivot-row-dimension-header {
                align-items: center;

                .igx-grid-th__icons {
                    padding-right: pad-inline(
                        map.get($grid-header-padding-inline, 'compact'),
                        map.get($grid-header-padding-inline, 'cosy'),
                        map.get($grid-header-padding-inline, 'comfortable')
                    );
                }
            }

            &:last-of-type {
                igx-pivot-row-dimension-header {
                    border-inline-end: 0;
                }
            }
        }
    }

    // Pivot grid END
}
