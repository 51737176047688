.mdc-slider__thumb-knob {
  background-color: #000 !important;
  border-color: #000 !important;
}

.igx-slider-thumb-from__dot::before,
.igx-slider-thumb-to__dot::before {
  background: #000 !important;
}

.igx-slider {
  margin: 0 0.5rem;
}
