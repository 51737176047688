.filters {
  width: 100%;
  .popup-body {
    width: 100%;
  }
  .popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 348px;
    margin: 0 auto;

    .popup-title {
      font-size: 28px;
      color: $general-color;
      font-weight: 700;
    }

    .close-popup {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .popup-content-form {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.25rem;
    padding: 0;

    .form-group {
      max-width: 346px;
      width: 100%;
    }

    .popup-form-group {
      gap: 1.25rem;
      .subtitle {
        font-size: 20px;
        font-weight: 700;
        color: #000;
      }
    }
    .subtitle {
      padding-left: 0;
      font-size: 16px;
    }

    .label-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btn {
      color: #fff;
      width: 100%;
      font-weight: 500;
      max-width: 348px;
    }
  }
}

@media (max-width: $tablet-width) {
  .filters {
    .popup-header {
      .close-popup {
        display: none;
      }
      .popup-title {
        color: #000;
        font-size: 24px;
      }
      display: flex;
      justify-content: center;
    }
    .popup-content-form {
      margin-top: 2rem;
      gap: 0.5rem;
      .popup-form-group {
        margin-top: 0;
        gap: 0.5rem;
        margin-bottom: 1rem;
        .subtitle {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-height: 900px) {
  .filters {
    .popup-content-form {
      margin-top: 2rem;
      gap: 1rem;
    }
  }
}
