@import "../../../styles/variables/variables.scss";

button {
  outline: none;
}

.btn {
  position: relative;
  background-color: $general-color;
  border: 2px solid $black-color;
  box-shadow: 4px 4px 0px #000;
  border-radius: 8px;
  height: 60px;
  font-weight: 600;
  font-size: 22px;
  cursor: pointer;
  transition: all 0.1s ease;
  font-family: "Poppins";
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px #000;
  }

  &.btn-loading {
    cursor: wait;
    pointer-events: none;
    color: transparent !important;
    user-select: none !important;
    &::after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      border: 2px solid #fff;
      border-top-color: transparent;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.btn-disabled {
  cursor: not-allowed;
  &:active {
    transform: none;
    box-shadow: 4px 4px 0px #000;
  }
}

.error-btn {
  background-color: $error-color !important;
}
