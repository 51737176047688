.popup-btn-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  padding: 0.75rem 0.5rem;
  border: 2px solid #000;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: #fff;
  right: 3rem;
  overflow: hidden;
  top: 2rem;

  .second-popup-btn {
    border: none;
    outline: none;
    background-color: transparent;
    color: #ea3e3e;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.1s ease;
    padding: 0 0.25rem;

    &:hover {
      color: #000;
    }
  }
}

.project-more-info {
  padding: 0;
  img {
    padding: 2px 0;
    border-radius: 8px;
    transition: all 0.1s ease;

    &:hover {
      background-color: #cfcfcf;
    }
  }
}
